// src/components/Counter.js
import React from 'react';

function Counter({
  counter,
  onDelete,
  onFuseToggle,
  onIncrement,
  onDecrement,
  isOwnCounter,
  isFusing,
  style,
  isFusable,
}) {
  const handleNumberClick = (e, numberKey) => {
    if (e.type === 'contextmenu') {
      e.preventDefault();
      e.stopPropagation();
      onDecrement(counter.id, numberKey);
    } else if (e.button === 0) {
      e.stopPropagation();
      onIncrement(counter.id, numberKey);
    }
  };

  return (
    <div
      className="counter"
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        ...style,
      }}
    >
      {/* Row 1: Text */}
        <div className="counter-row" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {counter.text && (
            <span className="counter-text mx-1">{counter.text}</span>
          )}
        </div>
      
      {/* Row 2: Numbers */}
      <div className="counter-row" style={{ display: 'flex', justifyContent: 'center', gap: '12px', alignItems: 'center' }}>
        {counter.number1 !== null && (
          <span
            className="counter-number"
            onClick={(e) => handleNumberClick(e, 'number1')}
            onContextMenu={(e) => handleNumberClick(e, 'number1')}
          >
            {counter.number1}
          </span>
        )}
        {counter.number2 !== null && (
          <span
            className="counter-number"
            onClick={(e) => handleNumberClick(e, 'number2')}
            onContextMenu={(e) => handleNumberClick(e, 'number2')}
          >
            {counter.number2}
          </span>
        )}
        <div className="counter-buttons">
          {isOwnCounter && (
            <button 
              className="delete-btn"
              onClick={() => onDelete(counter.id)}
            >
              <img 
                src="https://static.thenounproject.com/png/632591-200.png"
                alt="Delete"
                style={{
                  width: '15px',
                  height: '15px',
                  cursor: 'pointer',
                  filter: 'invert(1)',
                }}
              />
            </button>
          )}
        </div>
      </div>

      {/* Row 3: Fuse Button */}
      {isOwnCounter && !counter.fusedCardId && counter.isFusable && (  // Changed from isFusable to counter.isFusable
        <div className="counter-buttons" style={{ display: 'flex', justifyContent: 'center', gap: '4px' }}>
          <button 
            onClick={() => onFuseToggle(counter.id)}
            style={{
              backgroundColor: isFusing ? '#D6D6D6' : '#000000',
              border: 'none',
              padding: '6px',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
          >
            <img 
              src="https://www.svgrepo.com/show/354892/connect.svg" 
              alt={isFusing ? 'Cancel Fuse' : 'Fuse'}
              style={{
                width: '15px',
                height: '15px',
                filter: 'invert(1)',
              }}
            />
          </button>
        </div>
      )}
    </div>
  );
}

export default Counter;
