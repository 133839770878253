// src/pages/Room.js

import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { io } from 'socket.io-client';
import { useAuth0 } from '@auth0/auth0-react';
import '../styles/Room.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Card from '../components/Card';
import Zone from '../components/Zone';
import BattlefieldZone from '../components/BattlefieldZone';
import { Menu, ControlledMenu, MenuItem, SubMenu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import { useNavigate } from 'react-router-dom';
import Draggable from 'react-free-draggable';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import { v4 as uuidv4 } from 'uuid';
import Counter from '../components/Counter';
import LogMessage from '../components/LogMessage';
import soundEffect from '../urTurn.mp3';

function Room() {
  const { roomId } = useParams();
  const [room, setRoom] = useState(null);
  const { user, isAuthenticated } = useAuth0();
  const [socket, setSocket] = useState(null);
  const [gameState, setGameState] = useState(null);
  const [deckList, setDeckList] = useState([]);
  const [showDeckSelection, setShowDeckSelection] = useState(true);
  const [hasSelectedDeck, setHasSelectedDeck] = useState(false);
  const [showMulliganScreen, setShowMulliganScreen] = useState(false);
  const [openingHand, setOpeningHand] = useState([]);
  const [viewedPlayerId, setViewedPlayerId] = useState(null);
  const [hostId, setHostId] = useState(null);
  const [hostEmail, setHostEmail] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const [openMenuCardId, setOpenMenuCardId] = useState(null);
  const [isCtrlPressed, setIsCtrlPressed] = useState(false);
  const [isShiftPressed, setIsShiftPressed] = useState(false);
  const [previewCard, setPreviewCard] = useState(null);
  const [isPreviewOnRightSide, setIsPreviewOnRightSide] = useState(false);
  const [isZoneMenuOpen, setIsZoneMenuOpen] = useState(false);
  const [zoneToView, setZoneToView] = useState(null); // 'graveyard' or 'exile'
  const [zoneCards, setZoneCards] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [cardNames, setCardNames] = useState({});
  const [handStartIndex, setHandStartIndex] = useState(0);
  const [scryCards, setScryCards] = useState([]);
  const [surveilGraveyardCards, setSurveilGraveyardCards] = useState([]);
  const [surveilCards, setSurveilCards] = useState([]);
  const [isScryModalOpen, setIsScryModalOpen] = useState(false);
  const [isSurveilModalOpen, setIsSurveilModalOpen] = useState(false);
  const [scryTopCards, setScryTopCards] = useState([]);
  const [scryBottomCards, setScryBottomCards] = useState([]);
  const [surveilTopCards, setSurveilTopCards] = useState([]);
  const [isSearchingLibrary, setIsSearchingLibrary] = useState(false);
  const [isTokenModalOpen, setIsTokenModalOpen] = useState(false);
  const [tokenSearchTerm, setTokenSearchTerm] = useState('');
  const [tokenSearchResults, setTokenSearchResults] = useState([]);
  const [cardDataCache, setCardDataCache] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isTopFlipped, setIsTopFlipped] = useState(false);
  const [zoneOwner, setZoneOwner] = useState(null);
  const [revealedCards, setRevealedCards] = useState({});
  const [openCommanderDmg, setOpenCommanderDmg] = useState(false);
  const [openCommanderDmgAdd, setOpenCommanderDmgAdd] = useState(false);
  const [openCommanderDmgRemove, setOpenCommanderDmgRemove] = useState(false);
  const [isCounterModalOpen, setIsCounterModalOpen] = useState(false);
  const [counterText, setCounterText] = useState('');
  const [isFusable, setIsFusable] = useState(false);
  const [counterNumber1, setCounterNumber1] = useState('');
  const [counterNumber2, setCounterNumber2] = useState('');
  const [counters, setCounters] = useState([]);
  const [fuseCounterId, setFuseCounterId] = useState(null);  
  const [gameLog, setGameLog] = useState([]);
  const [playerInfoId, setPlayerInfoId] = useState(null);
  const [isPlayerInfoModalOpen, setIsPlayerInfoModalOpen] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [isBottomRandomized, setIsBottomRandomized] = useState(false);
  const [isRevealedHandModal, setIsRevealedHandModal] = useState(false);
  const [revealedHandData, setRevealedHandData] = useState(null);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const urTurn = new Audio(soundEffect);
  const lastTapTimeRef = useRef(0);
  const [overflow, setOverflow] = useState('auto');

  Modal.setAppElement('#root'); // Or your app's root ID

  const navigate = useNavigate();

  const notify = (message, dieType) => {
    const dieIcons = {
      4: 'https://static.thenounproject.com/png/2453696-200.png',
      6: 'https://static.thenounproject.com/png/2453695-200.png',
      8: 'https://static.thenounproject.com/png/2453699-200.png',
      10: 'https://static.thenounproject.com/png/2453698-200.png',
      12: 'https://static.thenounproject.com/png/2453697-200.png',
      20: 'https://i.imgur.com/vabnCtw.png',
    };

    const defaultIcon = 'https://cdn-icons-png.flaticon.com/512/6545/6545894.png';

    toast.info(
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={dieIcons[dieType] || defaultIcon}
          alt="Die Icon"
          style={{ width: '50px', height: '50px', marginRight: '10px' }}
        />
        <span>{message}</span>
      </div>,
      {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        icon: false,
        style: {
          textAlign: 'center',
          fontFamily: 'Antonio, sans-serif',
          color: 'black',
        },
        progressStyle: {
          background: '#885e77',
        },
      }
    );
  };

  // Initialize Socket.io connection
  useEffect(() => {
    if (isAuthenticated && user) {
      const newSocket = io.connect(backendUrl, {
        query: {
          userId: user.sub,
        },
      });

      setSocket(newSocket);

      // Join the room
      newSocket.emit('joinRoom', {
        roomId,
        userId: user.sub,
        username: user['https://replacementeffect.com/username'],
        userEmail: user.email,
      });

      // Listen for kick notification
      newSocket.on('kicked', () => {
        alert('You have been kicked from the room.');
        navigate('/'); // Redirect to the home page
      });

      // Listen for game state updates
      newSocket.on('gameStateUpdate', (updatedState) => {
        setGameState(updatedState);
        setCounters(updatedState.counters || []);

        const player = updatedState.players.find((p) => p.userId === user.sub);

        // Check if the player has selected a deck
        if (player && player.hasSelectedDeck) {
          setHasSelectedDeck(true); // Player has selected a deck
          setShowDeckSelection(false);
        } else {
          setHasSelectedDeck(false); // Player hasn't selected a deck yet
          setShowDeckSelection(true);
        }

        // Show mulligan screen if needed
        if (player && player.hand && player.hand.length > 0 && !player.hasKeptHand) {
          setOpeningHand(player.hand);
          setShowMulliganScreen(true);
        } else {
          setShowMulliganScreen(false);
        }

        // Add a dummy player if no other players are available
        if (updatedState.players.length === 1) {
          const dummyPlayer = {
            userId: 'dummy',
            username: 'Soon to be a player',
            lifeTotal: 0,
            hand: [],
            battlefield: [],
            graveyard: [],
            exile: [],
            library: [],
            commandZone: [],
            color: 'white',
          };
          updatedState.players.push(dummyPlayer);
        }

        // Ensure viewedPlayerId is valid
        const isViewedPlayerStillInGame = updatedState.players.some(
          (p) => p.userId === viewedPlayerId
        );

        if (!isViewedPlayerStillInGame) {
          const otherPlayer = updatedState.players.find((p) => p.userId !== user.sub);
          setViewedPlayerId(otherPlayer ? otherPlayer.userId : user.sub);
        }

        // Set default viewedPlayerId to the dummy player
        if (!viewedPlayerId || viewedPlayerId === user.sub) {
          const otherPlayer = updatedState.players.find((p) => p.userId !== user.sub);
          setViewedPlayerId(otherPlayer ? otherPlayer.userId : 'dummy');
        }
      });

      // Listen for die roll results
      newSocket.on('dieRollResult', ({ result, sides, username }) => {
        notify(`${username} Rolled a ${result} out of a ${sides} sided die`, sides);
      });

      // Clean up on unmount
      return () => {
        newSocket.disconnect();
      };
    }
  }, [isAuthenticated, user, roomId, viewedPlayerId]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    axios
      .get(`${backendUrl}/api/rooms/${roomId}`)
      .then((response) => {
        console.log('Room data:', response.data);
        setRoom(response.data);
        setHostId(response.data.hostId);
      })
      .catch((error) => console.error('Error fetching room:', error));
  }, [roomId]);

  // Fetch user's decks
  useEffect(() => {
    if (isAuthenticated && user) {
      const email = user.email;
      axios
        .get(`${backendUrl}/api/decks/${email}`)
        .then((response) => setDeckList(response.data))
        .catch((error) => console.error('Error fetching decks:', error));
    }
  }, [isAuthenticated, user]);

  // After fetching room details
  useEffect(() => {
    axios
      .get(`${backendUrl}/api/rooms/${roomId}`)
      .then((response) => {
        setRoom(response.data);
        setHostId(response.data.hostId);
        setHostEmail(response.data.hostEmail);
      })
      .catch((error) => console.error('Error fetching room:', error));
  }, [roomId]);

  useEffect(() => {
    if (!socket) return;
  
    // Clean up the listener on unmount
    return () => {
      socket.off('gameLogUpdate');
    };
  }, [socket]);  

  // Function to fetch card names
  const fetchCardNames = async (cards) => {
    const newCardNames = { ...cardNames };
    for (const card of cards) {
      if (!newCardNames[card.cardId]) {
        const response = await fetch(`https://api.scryfall.com/cards/${card.cardId}`);
        const data = await response.json();
        newCardNames[card.cardId] = data.name;
      }
    }
    setCardNames(newCardNames);
  };

  useEffect(() => {
    if (isZoneMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isZoneMenuOpen]);

  // When zoneCards change, fetch card names
  useEffect(() => {
    if (isZoneMenuOpen && zoneCards.length > 0) {
      fetchCardNames(zoneCards);
    }
  }, [isZoneMenuOpen, zoneCards]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Control') setIsCtrlPressed(true);
      if (e.key === 'Shift') setIsShiftPressed(true);
    };

    const handleKeyUp = (e) => {
      if (e.key === 'Control') setIsCtrlPressed(false);
      if (e.key === 'Shift') setIsShiftPressed(false);
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  useEffect(() => {

      const handleKeyPress = (e) => {
        if (e.key.toLowerCase() === 'p' && gameState) {
          if(!isZoneMenuOpen && !isTokenModalOpen){
            if (gameState.activePlayerId !== user.sub) {
              console.log('It is not your turn');
              return;
            }
            socket.emit('passTurn', { roomId, userId: user.sub });
          }
        }
  };

  window.addEventListener('keypress', handleKeyPress);

  return () => {
    window.removeEventListener('keypress', handleKeyPress);
  };
}, [socket, roomId, user.sub, gameState]);

useEffect(() => {
  if (!socket) return;

  socket.on('turnChanged', ({ newActivePlayerId }) => {
    // Don't change the viewed player if it's the current player's turn
    if (newActivePlayerId !== user.sub) {
      setViewedPlayerId(newActivePlayerId);
    }
    else{
      if (document.hidden || !document.hasFocus()){
        urTurn.play();
      }
      toast.info("Your Turn");
    }
  });

  return () => {
    socket.off('turnChanged');
  };
}, [socket, user.sub]);

  useEffect(() => {
    if (socket) {
      socket.on('scryCards', ({ cards }) => {
        setScryCards(cards);
        setIsScryModalOpen(true);
      });
      socket.on('surveilCards', ({ cards }) => {
        setSurveilCards(cards);
        setIsSurveilModalOpen(true);
      });
    }
  }, [socket]);  

  const handleDeckSelect = (deckId) => {
    setShowDeckSelection(false);
    setHasSelectedDeck(true);

    // Notify server of selected deck
    if (socket) {
      socket.emit('selectDeck', {
        roomId,
        userId: user.sub,
        username: user.username || user.email,
        deckId,
        playerColor: currentPlayer.color,
      });
    }
  };

  const handleKickPlayer = (playerEmail, playerIdToKick) => {
    const confirmed = window.confirm('Are you sure you want to kick this player?');
    if (confirmed && socket) {
      // Update viewedPlayerId if the kicked player is currently viewed
      if (viewedPlayerId === playerIdToKick) {
        const otherPlayer = gameState.players.find(
          (p) => p.userId !== playerIdToKick && p.userId !== user.sub
        );
        setViewedPlayerId(otherPlayer ? otherPlayer.userId : user.sub);
      }
  
      socket.emit('kickPlayer', {
        roomId,
        kickedPlayerEmail: playerEmail,
        playerIdToKick,
      });
    }
  };

  const handleMulligan = () => {
    if (socket) {
      socket.emit('mulligan', { roomId, userId: user.sub });
    }
  };

  const handleKeep = () => {
    setShowMulliganScreen(false);
    if (socket) {
      socket.emit('keepHand', { roomId, userId: user.sub });
    }
  };

  const handleCardAction = (action, cardId, fromZone) => {
    if (isDragging) {
      return;
    }

    // Ensure the card belongs to the current player
    const cardBelongsToPlayer = currentPlayer && currentPlayer[fromZone]?.some((card) => card.id === cardId);

    if (!cardBelongsToPlayer) {
      console.warn('Attempted to interact with a card that does not belong to you.');
      return;
    }

    if (socket) {
      socket.emit('gameAction', {
        roomId,
        action,
        cardId,
        userId: user.sub,
        fromZone,
      });

      // Immediately update zoneCards if we're viewing a zone
      if (isZoneMenuOpen && zoneToView) {
        // Get the updated cards for the zone being viewed
        const updatedZoneCards = currentPlayer[zoneToView].filter((card) => card.id !== cardId);
        setZoneCards(updatedZoneCards);
      }
    }
  };

  const handleAdjustLife = (amount) => {
    if (socket) {
      socket.emit('adjustLife', { roomId, userId: user.sub, amount });
    }
  };

  const handleCommanderDmg = (otherPlayer, amount) => {
    if (socket) {
      socket.emit('adjustCommanderDmg', { 
        roomId, 
        userId: user.sub, 
        otherPlayerId: otherPlayer.userId, 
        amount 
      });
    }
  };

  const handleRollDie = (sides, username) => {
    if (socket) {
      socket.emit('rollDie', { roomId, sides, username, userId: user.sub });
    }
  };

  const getInitialCardPosition = () => {
    const battlefield = document.querySelector('.battlefield-cards');
    if (!battlefield) return { x: 0, y: 0 };

    const rect = battlefield.getBoundingClientRect();
    return {
      x: (rect.width - 90) / 2, // 90 is the card width from your CSS
      y: (rect.height*2 - 130) / 2 // 130 is the battlefield-cards height from your CSS
    };
  };

  const handleDropCard = (cardId, fromZone, toZone) => {
    if (toZone === 'battlefield') {
      const initialPos = getInitialCardPosition();
      socket.emit('moveCard', {
        roomId,
        userId: currentPlayer.userId,
        cardId,
        fromZone,
        toZone,
        position: initialPos
      });
    } else {
      socket.emit('moveCard', {
        roomId,
        userId: currentPlayer.userId,
        cardId,
        fromZone,
        toZone
      });
    }
  };

  const handleChangeDeck = () => {
    // Notify the server to reset the player's state
    const confirmed = window.confirm('Are you sure you want to change?');
    if(confirmed){
      if (socket) {
        socket.emit('changeDeck', { roomId, userId: user.sub });
      }
  
      // Reset local state
      setShowDeckSelection(true);
      setHasSelectedDeck(false);
      setShowMulliganScreen(false);
      setOpeningHand([]);
    }
  };

  // Drag Stuff
  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = (cardId, x, y) => {
    setIsDragging(false);

    // Update the local state immediately
    setGameState((prevState) => {
      const updatedPlayers = prevState.players.map((player) => {
        if (player.userId === user.sub) {
          return {
            ...player,
            cardPositions: {
              ...player.cardPositions,
              [cardId]: { x, y },
            },
          };
        }
        return player;
      });

      return {
        ...prevState,
        players: updatedPlayers,
      };
    });

    // Emit the updated position to the server
    if (socket) {
      socket.emit('updateCardPosition', { roomId, userId: user.sub, cardId, x, y });
    }
  };

  const handleZindex = (cardId) => {
    // Find all card elements in the battlefield
    const allCards = document.querySelectorAll('.battlefield-cards .draggables');
    const draggedCard = document.querySelector(`[data-card-id="${cardId}"]`);

    // Only update z-indices if the dragged card isn't already on top
    if (draggedCard.style.zIndex !== (5000 + allCards.length).toString()) {
      allCards.forEach((card) => {
        const currentZIndex = parseInt(card.style.zIndex, 10) || 5000;
        card.style.zIndex = (currentZIndex - 1).toString();
      });

      if (draggedCard) {
        // Set the dragged card's z-index to the highest value (5000 + number of cards)
        draggedCard.style.zIndex = (5000 + allCards.length).toString();
      }
    }
  };

  const handleZoneClick = (zoneName, cards, zoneOwner) => {
    setZoneToView(zoneName);
    setZoneCards(cards);
    setIsZoneMenuOpen(true);
    setSearchTerm(''); // Reset search term when opening new zone
    setZoneOwner(zoneOwner); // Set the zone owner

    if(zoneName === "library"){
      setIsSearchingLibrary(true);
    }

    if(socket) {
      socket.emit('zoneClicked', { roomId, zoneName, zoneOwner: user.sub });
    }
  };  

  const handlePlayerInfoClick = (playerId) => {
    setIsPlayerInfoModalOpen(true);
    setPlayerInfoId(playerId);
  };

  const getCardName = (cardId) => {
    return cardNames[cardId] || '';
  };

  const getCardPosition = (cardId) => {
    const position = currentPlayer.cardPositions?.[cardId];
    if (position) {
      return {
        x: position.x,
        y: position.y,
      };
    }
    return { x: 0, y: 0 }; // Default position if none is found
  };

  const getActivePlayerCardPosition = (cardId, player) => {
    const position = player.cardPositions?.[cardId];
    if (position) {
      return {
        top: `${position.y}px`,
        left: `${position.x}px`,
      };
    }
    return { top: '0px', left: '0px' }; // Default position if none is found
  };

  const getCounterPosition = (counter) => {
    if (counter.fusedCardId) {
      const cardPosition = getCardPosition(counter.fusedCardId);
      return {
        x: cardPosition.x,
        y: cardPosition.y,
      };
    }
    return counter.position || { x: 0, y: 0 };
  };  

  const getActivePlayerCounterPosition = (counter, player) => {
    if (counter.fusedCardId) {
      const cardPosition = player.cardPositions?.[counter.fusedCardId];
      return {
        top: `${cardPosition.y}px`,
        left: `${cardPosition.x}px`,
      };
    }
    return { top: '0px', left: '0px' }; // Default position if none is found
  };

  const handleLeaveRoom = () => {
    const confirmed = window.confirm('Are you sure you want to leave the room?');
    if (confirmed && socket) {
      socket.emit('leaveRoom', { roomId, userId: user.sub });
      navigate('/'); // Redirect to the homepage
    }
  };

  const handleCardTap = (cardId) => {
    if (socket) {
      socket.emit('toggleCardTap', { roomId, userId: user.sub, cardId });
    }
  };

  // Add these menu handlers for the library
  const handleDrawCard = () => {
    if (socket) {
      socket.emit('gameAction', {
        roomId,
        action: 'draw',
        userId: user.sub,
        fromZone: 'library',
      });
    }
  };

  const handleDrawMultipleCards = (amount) => {
    if (socket) {
      socket.emit('gameAction', {
        roomId,
        action: 'drawMultiple',
        userId: user.sub,
        fromZone: 'library',
        amount,
      });
    }
  };

  const handleMillMultipleCards = (amount) => {
    if (socket) {
      socket.emit('gameAction', {
        roomId,
        action: 'millMultiple',
        userId: user.sub,
        fromZone: 'library',
        amount,
      });
    }
  };

  const handleSurveil = (amount) => {
    if (socket) {
      socket.emit('gameAction', {
        roomId,
        action: 'surveil',
        userId: user.sub,
        fromZone: 'library',
        amount,
      });
    }
  };

  const handleScry = (amount) => {
    if (socket) {
      socket.emit('gameAction', {
        roomId,
        action: 'scry',
        userId: user.sub,
        fromZone: 'library',
        amount,
      });
    }
  };

  const handleSurveilCardPlacement = (cardId, placement) => {
    const card = surveilCards.find((c) => c.id === cardId);
    if (!card) return;
    setSurveilCards((prev) => prev.filter((c) => c.id !== cardId));
    if (placement === 'top') {
      setSurveilTopCards((prev) => [...prev, card]);
    } else if (placement === 'graveyard') {
      setSurveilGraveyardCards((prev) => [...prev, card]);
    }
  };

  const handleScryCardPlacement = (cardId, placement) => {
    const card = scryCards.find((c) => c.id === cardId);
    if (!card) return;
    setScryCards((prev) => prev.filter((c) => c.id !== cardId));
    if (placement === 'top') {
      setScryTopCards((prev) => [...prev, card]);
    } else if (placement === 'bottom') {
      setScryBottomCards((prev) => [...prev, card]);
    }
  };  

  const finishSurveil = () => {
    // Check if there are still cards in the available section
    if (surveilCards.length > 0) {
      alert("Please place all cards either on top of your library or in your graveyard before finishing");
      return;
    }

    setIsSurveilModalOpen(false);
    if (socket) {
      socket.emit('finishSurveil', {
        roomId,
        userId: user.sub,
        surveilTopCards: surveilTopCards.map(card => card.id),
        surveilGraveyardCards: surveilGraveyardCards.map(card => card.id),
      });
    }
    // Reset scry states
    setSurveilCards([]);
    setSurveilTopCards([]);
    setSurveilGraveyardCards([]);
  };
  
  const finishScry = () => {
    // Check if there are still cards in the available section
    if (scryCards.length > 0) {
      alert("Please place all cards either on top or bottom of your library before finishing");
      return;
    }

    setIsScryModalOpen(false);
    setIsBottomRandomized(false);
    if (socket) {
      socket.emit('finishScry', {
        roomId,
        userId: user.sub,
        scryTopCards: scryTopCards.map(card => card.id),
        scryBottomCards: scryBottomCards.map(card => card.id),
        isRandomBottom: isBottomRandomized,
      });
    }
    // Reset scry states
    setScryCards([]);
    setScryTopCards([]);
    setScryBottomCards([]);
  };  

  const handleCardFlip = (cardId) => {
    if (socket) {
      socket.emit('toggleCardFlip', {
        roomId,
        cardId,
      });
    }
  };  

  const handleRevealTopCard = () => {
    if (socket) {
      socket.emit('revealTopCard', {
        roomId,
        userId: user.sub,
      });
    }
  };

  const handleKeepTopFlipped = () => {
    if (socket) {
      setIsTopFlipped(!isTopFlipped);
      socket.emit('keepTopFlipped', { roomId, userId: user.sub });
    }
  };

  const handleShuffle = () => {
    if (socket) {
      socket.emit('shuffleLibrary', { roomId, userId: user.sub });
    }
  };

  const handleMoveTo = (zone, isBottom) => {
    if (socket) {
      const initialPos = getInitialCardPosition();
      socket.emit('moveTo', { roomId, userId: user.sub, toZone: zone, position: initialPos, isBottom });
    }
  };

  const handleMoveXTo = (zone, amount) => {
    if (socket) {
      socket.emit('moveXTo', { roomId, userId: user.sub, toZone: zone, cardAmount: amount });
    }
  };

  const handleMoveXDown = (amount) => {
    if (socket) {
      socket.emit('moveXDown', { roomId, userId: user.sub, amount });
    }
  };

  const handleCloneCard = (cardId, isFlipped) => {
    if (socket) {
      socket.emit('cloneCard', { roomId, userId: user.sub, cardId, isFlipped });
    }
  };

  const handleShowPreview = (cardImage, isRightSide) => {
    setPreviewCard(cardImage);
    setIsPreviewOnRightSide(isRightSide);
  };

  const handleHidePreview = () => {
    setPreviewCard(null);
  };

  const handleUntapAll = () => {
    if (socket) {
      socket.emit('untapAll', { roomId, userId: user.sub });
    }
  };

const handleTokenSearch = async () => {
  const searchTerm = tokenSearchTerm;
  if (searchTerm.trim() === '') {
    setTokenSearchResults([]);
    return;
  }

  try {
    const query = `${searchTerm} (type:token OR type:emblem)`;
    const encodedQuery = encodeURIComponent(query);
    const response = await axios.get(
      `https://api.scryfall.com/cards/search?q=${encodedQuery}`
    );

    // Filter out tokens without images
    const tokensWithImages = response.data.data.filter(
      (card) =>
        (card.image_uris && card.image_uris.border_crop) ||
        (card.card_faces && card.card_faces[0].image_uris.border_crop)
    );

    setTokenSearchResults(tokensWithImages);
  } catch (error) {
    console.error('Error fetching token data:', error);
  }
};

const handleTokenSelect = (card) => {
  setIsTokenModalOpen(false);

  let imageUrl = '';
  if (card.image_uris && card.image_uris.border_crop) {
    imageUrl = card.image_uris.border_crop;
  } else if (card.card_faces && card.card_faces[0].image_uris.border_crop) {
    imageUrl = card.card_faces[0].image_uris.border_crop;
  } else {
    imageUrl = 'default_image_url'; // Provide a default image or handle accordingly
  }

  // Send a request to the server to create the token
  if (socket) {
    socket.emit('createToken', {
      roomId,
      userId: user.sub,
      tokenCardId: card.id,
      tokenName: card.name,
      imageUrl,
    });
  }
};

const handleAddCounter = (e) => {
  e.preventDefault();

  const newCounter = {
    id: uuidv4(), // Generate a unique ID
    text: counterText.trim() || null,
    number1: counterNumber1 !== '' ? parseInt(counterNumber1) : null,
    number2: counterNumber2 !== '' ? parseInt(counterNumber2) : null,
    position: { x: 100, y: 100 }, // Initial position
    isFused: false,
    fusedCardId: null,
    isFusing: false,
    isFusable: isFusable,
    ownerId: user.sub, // To determine ownership
  };

  // Emit to server
  if (socket) {
    socket.emit('addCounter', { roomId, counter: newCounter });
  }

  // Reset form fields and close modal
  setCounterText('');
  setCounterNumber1('');
  setCounterNumber2('');
  setIsFusable(false);
  setIsCounterModalOpen(false);
};

const hexToRgb = (hex) => {
  // Remove the # if present
  hex = hex.replace('#', '');
  
  // Parse the hex values
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  
  return `${r}, ${g}, ${b}`;
};


  const currentPlayer = gameState && gameState.players.find((p) => p.userId === user.sub);

  useEffect(() => {
    if (currentPlayer) {
      // Adjust handStartIndex if necessary
      const maxIndex = Math.max(0, currentPlayer.hand.length - 7);
      if (handStartIndex > maxIndex) {
        setHandStartIndex(maxIndex);
      }
    }
  }, [currentPlayer ? currentPlayer.hand.length : null]);

  useEffect(() => {
    if (socket) {
      socket.on('scryCards', ({ cards }) => {
        setScryCards(cards);
        setScryTopCards([]); // Reset top cards
        setScryBottomCards([]); // Reset bottom cards
        setIsScryModalOpen(true);
      });
    }
  }, [socket]);  

  useEffect(() => {
    if (socket) {
      socket.on('counterAdded', ({ counter }) => {
        setCounters((prev) => [...prev, counter]);
      });
  
      socket.on('counterUpdated', ({ counter }) => {
        setCounters((prev) =>
          prev.map((c) => (c.id === counter.id ? counter : c))
        );
      });
  
      socket.on('counterDeleted', ({ counterId }) => {
        setCounters((prev) => prev.filter((c) => c.id !== counterId));
      });
    }
  }, [socket]);  

  useEffect(() => {
    const logMessages = document.querySelector('.log-messages');
    if (logMessages) {
      logMessages.scrollTop = logMessages.scrollHeight;
    }
  }, [gameState?.gameLog]);

  const handleDeleteCounter = (counterId) => {
    if (socket) {
      socket.emit('deleteCounter', { roomId, counterId });
    }
  };
  
  const handleFuseToggle = (counterId) => {
    setFuseCounterId((prevId) => (prevId === counterId ? null : counterId));
  };
  
  const handleIncrementCounter = (counterId, numberKey) => {
    const counter = counters.find((c) => c.id === counterId);
    if (counter) {
      const updatedCounter = {
        ...counter,
        [numberKey]: (counter[numberKey] || 0) + 1,
      };
      socket.emit('updateCounter', { roomId, counter: updatedCounter });
    }
  };
  
  const handleDecrementCounter = (counterId, numberKey) => {
    const counter = counters.find((c) => c.id === counterId);
    if (counter) {
      const updatedCounter = {
        ...counter,
        [numberKey]: (counter[numberKey] || 0) - 1,
      };
      socket.emit('updateCounter', { roomId, counter: updatedCounter });
    }
  };  
  
  const handleFuseCounterToCard = (cardId) => {
    if (fuseCounterId) {
      // Count how many counters are already fused to this card
      const fusedCountersCount = counters.filter(c => c.fusedCardId === cardId).length;
      
      // If there are already 3 counters fused, prevent adding more
      if (fusedCountersCount >= 3) {
        toast.error('Maximum of 3 counters can be fused to a card');
        setFuseCounterId(null);
        return;
      }
  
      const counter = counters.find((c) => c.id === fuseCounterId);
      if (counter) {
        const updatedCounter = {
          ...counter,
          isFused: true,
          fusedCardId: cardId,
        };
        socket.emit('updateCounter', { roomId, counter: updatedCounter });
        setFuseCounterId(null);
      }
    }
  };

  const handleCounterAdjust = (type, amount) => {
    if (!socket || playerInfoId !== currentPlayer.userId) return;
  
    const counterTypes = {
      poison: 'adjustPoisonCounters',
      experience: 'adjustExperienceCounters',
      energy: 'adjustEnergyCounters'
    };
  
    socket.emit(counterTypes[type], {
      roomId,
      userId: currentPlayer.userId,
      amount
    });
  };

  const handleAddAdditionalInfo = () => {
    if (!additionalInfo.trim()) return;
    
    if (socket) {
      socket.emit('updateAdditionalInfo', {
        roomId,
        userId: user.sub,
        action: 'add',
        info: additionalInfo.trim()
      });
    }
    setAdditionalInfo(''); // Clear input after adding
  };
  
  const handleRemoveAdditionalInfo = (index) => {
    if (socket) {
      socket.emit('updateAdditionalInfo', {
        roomId,
        userId: user.sub,
        action: 'remove',
        index
      });
    }
  };

const getFusedCounterPosition = (counter, cardId, allCounters) => {
  // Find all counters fused to this card
  const fusedCounters = allCounters.filter(c => c.fusedCardId === cardId);
  // Find index of current counter
  const counterIndex = fusedCounters.findIndex(c => c.id === counter.id);

  // Use percentages for positioning to make it responsive
  const positions = [
    { top: '75%'}, // Third counter at bottom-left
    { top: '30%'},  // Second counter at top-right
    { top: '-15%'},   // First counter at top-left
  ];

  const position = positions[counterIndex % positions.length] || positions[0];

  return {
    position: 'absolute',
    ...position,
    pointerEvents: 'auto',
    zIndex: 5001 + counterIndex,
  };
};
  

  useEffect(() => {
    if (gameState) {
      const allCardIds = new Set();
      gameState.players.forEach(player => {
        ['hand', 'library', 'battlefield', 'graveyard', 'exile', 'commandZone'].forEach(zone => {
          player[zone].forEach(card => {
            allCardIds.add(card.cardId);
          });
        });
      });
  
      // Fetch card data for cardIds not in cache
      const cardIdsToFetch = Array.from(allCardIds).filter(cardId => !cardDataCache[cardId]);

      if (cardIdsToFetch.length > 0) {
        Promise.all(cardIdsToFetch.map(cardId => 
          fetch(`https://api.scryfall.com/cards/${cardId}`)
            .then(response => response.json())
            .then(data => ({ cardId, data }))
        ))
        .then(results => {
          const newCardData = {};
          results.forEach(({ cardId, data }) => {
            newCardData[cardId] = data;
          });
          setCardDataCache(prevCache => ({ ...prevCache, ...newCardData }));
        })
        .catch(error => console.error('Error fetching card data:', error));
      }
    }
  }, [gameState]);

  // Add this useEffect to keep local state in sync with game state
  useEffect(() => {
    if (gameState && currentPlayer) {
      setIsTopFlipped(currentPlayer.keepTopFlipped || false);
    }
  }, [gameState, currentPlayer]);

  useEffect(() => {
    if (socket) {
      socket.on('cardRevealed', ({ cardId }) => {
        toast.info(`A card was revealed to you`);
        setRevealedCards(prev => ({
          ...prev,
          [cardId]: true
        }));
      });
    }
  }, [socket]);

const handleRevealToPlayer = (cardId, playerId) => {
  if (socket) {
    socket.emit('revealCardTo', {
      roomId,
      cardId,
      revealToPlayerId: playerId,
    });
    const player = gameState.players.find((p) => p.userId === playerId);
    toast.info(`Card revealed to ${player.username}`);
  }
};

const handleRevealHandTo = (currentPlayerId, playerToRevealId) => {
  if (socket) {
    socket.emit('revealHandTo', {
      roomId,
      currentPlayerId,
      playerToRevealId,
    });
  }
};

useEffect(() => {
  if (socket) {
    socket.on('revealHand', ({ fromPlayerId, fromPlayerName, hand }) => {
      setRevealedHandData({
        fromPlayerId,
        fromPlayerName,
        hand,
      });
      setIsRevealedHandModal(true);
    });

    // Clean up the event listener when component unmounts or socket changes
    return () => {
      socket.off('revealHand');
    };
  }
}, [socket]);

useEffect(() => {
  if (socket) {
    socket.on('handRevealed', ({ toPlayerName }) => {
      toast.info(`You have revealed your hand to ${toPlayerName}`);
    });

    return () => {
      socket.off('handRevealed');
    };
  }
}, [socket]);

const handleSendRandomCardTo = (targetZone) => {
  if (socket && currentPlayer.hand.length > 0) {
    const randomIndex = Math.floor(Math.random() * currentPlayer.hand.length);
    const randomCard = currentPlayer.hand[randomIndex];

    socket.emit('sendRandomCardTo', {
      roomId,
      userId: user.sub,
      cardId: randomCard.id,
      targetZone: targetZone,
    });
  } else {
    toast.error("You have no cards in hand.");
  }
};

const handleSendHandTo = (targetZone) => {
  if (socket && currentPlayer.hand.length > 0) {

    socket.emit('sendHandTo', {
      roomId,
      userId: user.sub,
      targetZone: targetZone,
    });
  } else {
    toast.error("You have no cards in hand.");
  }
}

const getCardImage = (card, isFlipped) => {
  const cardData = cardDataCache[card.cardId];

  if (!cardData) {
    return `https://api.scryfall.com/cards/${card.cardId}?format=image&version=border_crop`;
  }

  // Find the owner of the card
  const cardOwner = gameState.players.find((p) =>
    ['hand', 'library', 'battlefield', 'graveyard', 'exile', 'commandZone'].some((zone) =>
      p[zone].some((c) => c.id === card.id)
    )
  );

  const isRevealedToCurrentPlayer =
    cardOwner &&
    cardOwner.revealedToPlayers &&
    cardOwner.revealedToPlayers[card.id] &&
    cardOwner.revealedToPlayers[card.id].includes(currentPlayer.userId);

  // If the card is revealed to current player, show it regardless of flip state
  if (isRevealedToCurrentPlayer) {
    return (
      cardData.image_uris?.border_crop ||
      cardData.image_uris?.normal ||
      `https://api.scryfall.com/cards/${card.cardId}?format=image&version=border_crop`
    );
  }

  // Rest of your existing logic...
  const isDoubleFaced = cardData.card_faces && cardData.card_faces.length > 1;

  if (isDoubleFaced) {
    const faceIndex = isFlipped ? 1 : 0;
    const face = cardData.card_faces[faceIndex];
    return (
      face.image_uris?.border_crop ||
      face.image_uris?.normal ||
      `https://api.scryfall.com/cards/${card.cardId}?format=image&version=border_crop`
    );
  }

  if (!isFlipped) {
    return (
      cardData.image_uris?.border_crop ||
      cardData.image_uris?.normal ||
      `https://api.scryfall.com/cards/${card.cardId}?format=image&version=border_crop`
    );
  }

  // Return back face image or placeholder if the card is flipped
  return 'https://i.imgur.com/eKG7owo.png';
};



  const moveCardUp = (index) => {
    if (index === 0) return; // Already at the top
    setScryTopCards((prev) => {
      const newCards = [...prev];
      [newCards[index - 1], newCards[index]] = [newCards[index], newCards[index - 1]];
      return newCards;
    });
  };
  
  const moveCardUpSurveil = (index) => {
    if (index === 0) return; // Already at the top
    setSurveilTopCards((prev) => {
      const newCards = [...prev];
      [newCards[index - 1], newCards[index]] = [newCards[index], newCards[index - 1]];
      return newCards;
    });
  };

  const moveCardDownSurveil = (index) => {
    if (index === surveilTopCards.length - 1) return; // Already at the bottom
    setSurveilTopCards((prev) => {
      const newCards = [...prev];
      [newCards[index], newCards[index + 1]] = [newCards[index + 1], newCards[index]];
      return newCards;
    });
  };

  const moveCardDown = (index) => {
    if (index === scryTopCards.length - 1) return; // Already at the bottom
    setScryTopCards((prev) => {
      const newCards = [...prev];
      [newCards[index], newCards[index + 1]] = [newCards[index + 1], newCards[index]];
      return newCards;
    });
  };
  
  const moveCardBackToAvailable = (cardId) => {
    // Check if card is in scryTopCards
    let card = scryTopCards.find((c) => c.id === cardId);
    if (card) {
      setScryTopCards((prev) => prev.filter((c) => c.id !== cardId));
    } else {
      // Check if card is in scryBottomCards
      card = scryBottomCards.find((c) => c.id === cardId);
      if (card) {
        setScryBottomCards((prev) => prev.filter((c) => c.id !== cardId));
      } else {
        return;
      }
    }
    setScryCards((prev) => [...prev, card]);
  };

  const moveCardBackToAvailableSurveil = (cardId) => {
    // Check if card is in scryTopCards
    let card = surveilTopCards.find((c) => c.id === cardId);
    if (card) {
      setSurveilTopCards((prev) => prev.filter((c) => c.id !== cardId));
    } else {
      // Check if card is in surveilBottomCards
      card = surveilGraveyardCards.find((c) => c.id === cardId);
      if (card) {
        setSurveilGraveyardCards((prev) => prev.filter((c) => c.id !== cardId));
      } else {
        return;
      }
    }
    setSurveilCards((prev) => [...prev, card]);
  };


  if (!room) {
    return <div>Loading room...</div>;
  }

  if (showDeckSelection) {
    // Add the class to the body when deck selection is shown
    document.body.classList.add('body-deck-selection');

    const isKicked = room.kickedUsers?.includes(user.email);
    
    if (isKicked) {
      // Redirect to home if player is not in game or is kicked
      navigate('/');
      return null;
    }
    return (
      <div className='select-deck-screen'>
        <h1 className='text-center text-light my-3'>Select Your Deck</h1>
        <div className="deck-selection">

          {deckList.map((deck) => (
            <div key={deck._id} className="deck-item">
              <h3>{deck.name}</h3>
              <p>
                -
                {deck.commanders.map((commander, index) => (
                  <span key={commander.cardId}>
                    {commander.cardName}
                    {index < deck.commanders.length - 1 ? ' | ' : ''}
                  </span>
                ))}
                -
              </p>
              <button onClick={() => handleDeckSelect(deck._id)}>Select Deck</button>
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    // Remove the class when not showing deck selection
    document.body.classList.remove('body-deck-selection');
  }

  if (showMulliganScreen) {
    // Check if user is kicked or not in the game's player list
    const isPlayerInGame = gameState?.players.some(p => p.userId === user.sub);
    const isKicked = room.kickedUsers?.includes(user.email);
    
    if (!isPlayerInGame || isKicked) {
      // Redirect to home if player is not in game or is kicked
      navigate('/');
      return null;
    }
  
    // Render mulligan screen
    return (
      <div className="mulligan-screen">
        <h2>Your Opening Hand</h2>
        <div className="hand-cards">
          {openingHand.map((card) => (
            <img
              key={card.id}
              src={`https://api.scryfall.com/cards/${card.cardId}?format=image&version=border_crop`}
              alt={`Card ${card.cardId}`}
            />
          ))}
        </div>
        <div>
          <button onClick={handleKeep}>Keep</button>
          <button onClick={handleMulligan}>Mulligan</button>
        </div>
      </div>
    );
  }

  // Ensure currentPlayer exists
  if (!currentPlayer) {
    return <div>Loading player data...</div>;
  }

    // Calculate the number of cards to the left and right
    const cardsToLeft = handStartIndex;
    const cardsToRight = Math.max(0, currentPlayer.hand.length - (handStartIndex + 7));

  const viewedPlayer =
    gameState && gameState.players.find((p) => p.userId === viewedPlayerId);

  if (!viewedPlayer) {
    // Instead of reloading, update the viewedPlayerId to a valid player
    const otherPlayer = gameState.players.find((p) => p.userId !== user.sub);
    setViewedPlayerId(otherPlayer ? otherPlayer.userId : user.sub);

    // Optionally, you can display a loading message or a fallback UI
    return <div>Loading player data...</div>;
  }

  // Exclude the current user from the players list
  const otherPlayers = gameState
    ? gameState.players.filter((p) => p.userId !== user.sub)
    : [];

  // Determine if the viewed player is the current player
  const isOwnView = viewedPlayer.userId === currentPlayer.userId;

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="room-page room-container">
        <ToastContainer />
        <div className="main-content-room">
          {/* Upper Half */}
          <div className="upper-half">
            {/* Left Column */}
            <div className="left-column">
              {/* Other Players */}
              <h5 className="text-center mt-2 opponents-title w-50 pb-1 mx-auto shadow">
                  Opponents
                </h5>
              <div className="other-players px-2">
                {otherPlayers
                  .filter((player) => player.userId !== 'dummy') // Filter out the dummy player
                  .map((player) => (
                    <div
                      key={player.userId}
                      className={`p-1 player-info ${gameState.activePlayerId === player.userId ? 'active-player' : ''} ${viewedPlayerId === player.userId ? 'viewed-player' : ''}`}
                      onClick={() => setViewedPlayerId(player.userId)}
                      style={{
                        backgroundColor: 'black',
                        backgroundImage: `linear-gradient(rgba(${hexToRgb(player.color)}, 0.2), rgba(${hexToRgb(player.color)}, 0.2))`,
                      }}
                    >
                      <h4 className="d-flex align-items-center justify-content-between mb-0 pb-0">
                        <div className="d-flex flex-column align-items-center">
                          <h5 className='p-0 m-0'>{player.lifeTotal}</h5>
                          <img
                            className="mt-1"
                            src="https://www.iconpacks.net/icons/1/free-heart-icon-992-thumb.png"
                            alt="Heart"
                            style={{ width: '17px', height: '17px' }}
                          />
                        </div>
                        <span className="player-name">
                          {player.username.length > 4
                            ? `${player.username.slice(0, 4)}.`
                            : player.username}
                        </span>
                        <div className="d-flex flex-column align-items-center">
                          <h5 className='p-0 m-0'>{player.hand ? player.hand.length : 0}</h5>
                          <img
                            className="mt-1"
                            src="https://i.imgur.com/pMTApsy.png"
                            alt="Hand with cards"
                            style={{ width: '17px', height: '17px' }}
                          />
                        </div>
                      </h4>
                      <button className="player-info-button mx-2" onClick={() => handlePlayerInfoClick(player.userId)}>
                        <img 
                          src="https://cdn-icons-png.flaticon.com/512/32/32175.png"
                          alt="Player Info"
                          style={{ width: '20px', height: '20px' }}
                        />
                      </button>
                      {/* Only show the "Kick" button if the current user is the host */}
                      {hostEmail === user.email && (
                        <button
                        className="kick-button mx-2"
                        onClick={() => handleKickPlayer(player.userEmail, player.userId)}
                      >
                        <img 
                          src="https://cdn-icons-png.flaticon.com/256/1/1928.png"
                          alt="Kick Player"
                          style={{ width: '20px', height: '20px' }}
                        />
                      </button>
                      )}
                    </div>
                  ))}
              </div>
                {/* Game Log */}
                <div className="game-log">
                  <h5 className="text-center mt-2 log-title w-50 mx-auto shadow">Game Log</h5>
                  <div className="log-messages">
                  {gameState?.gameLog.slice(-50).map((logEntry, index) => {
                    return (
                    <LogMessage 
                      key={index} 
                      color={logEntry.color} // Use the color from the log entry
                      message={logEntry.message} 
                      />
                    );
                  })}
                  </div>
                </div>
            </div>

            {/* Middle Column */}
            <div className="middle-column">
              {/* Viewed Player's Battlefield */}
              <div className="battlefield">
                <Zone
                  zoneName="battlefield"
                  onDropCard={handleDropCard}
                  isOwnZone={isOwnView}
                >
                  <div className="battlefield-cards">
                  {counters
                      .filter((counter) => !counter.isFused && counter.ownerId === viewedPlayer.userId)
                      .map((counter) => (
                        <div
                          key={counter.id}
                          style={{
                            ...getActivePlayerCounterPosition(counter, viewedPlayer),
                            cursor: 'move',
                            background: 'transparent',
                            transformOrigin: 'center',
                          }}
                        >
                          <Counter
                            counter={counter}
                            onDelete={handleDeleteCounter}
                            onFuseToggle={handleFuseToggle}
                            onIncrement={handleIncrementCounter}
                            onDecrement={handleDecrementCounter}
                            isOwnCounter={counter.ownerId === currentPlayer.userId}
                            isFusing={fuseCounterId === counter.id}
                            isFusable={counter.isFusable}
                          />
                        </div>
                      ))}
                    {viewedPlayer.battlefield.map((card) => (
                      <div
                        key={card.id}
                        className="card"
                        style={{
                          ...getActivePlayerCardPosition(card.id, viewedPlayer),
                          position: 'absolute',
                          cursor: 'move',
                          background: 'transparent',
                          transformOrigin: 'center',
                        }}
                        onContextMenu={(e) => {
                          e.preventDefault();
                          const rect = e.currentTarget.getBoundingClientRect();
                          setAnchorPoint({
                            x: rect.left + rect.width / 2,
                            y: rect.top + rect.height / 2,
                          });
                          setOpenMenuCardId(card.id);
                        }}
                      >
                        <Card
                          cardId={card.cardId}
                          uniqueId={card.id}
                          cardImage={getCardImage(card, viewedPlayer.flippedCards?.includes(card.id))}
                          fromZone="battlefield"
                          isOwnCard={false}
                          isOnBattlefield={true}
                          isTapped={viewedPlayer.tappedCards?.includes(card.id)}
                          isFlipped={viewedPlayer.flippedCards?.includes(card.id)}
                          onShowPreview={handleShowPreview}
                          onHidePreview={handleHidePreview}
                        />
                        {counters
                          .filter((counter) => counter.fusedCardId === card.id)
                          .map((counter) => (
                            <Counter
                              key={counter.id}
                              counter={counter}
                              onDelete={handleDeleteCounter}
                              onFuseToggle={handleFuseToggle}
                              onIncrement={handleIncrementCounter}
                              onDecrement={handleDecrementCounter}
                              isOwnCounter={counter.ownerId === currentPlayer.userId}
                              isFusing={fuseCounterId === counter.id}
                              style={getFusedCounterPosition(counter, card.id, counters)}
                            />
                        ))}
                        <ControlledMenu
                          anchorPoint={anchorPoint}
                          onClose={() => setOpenMenuCardId(null)}
                          state={openMenuCardId === card.id ? 'open' : 'closed'}
                          direction="right"
                          align="center"
                          portal={true}
                          menuClassName="battlefield-card-menu"
                        >
                          <MenuItem onClick={() => handleCloneCard(card.id, viewedPlayer.flippedCards?.includes(card.id))}>
                            Clone
                          </MenuItem>
                        </ControlledMenu>
                      </div>
                    ))}
                  </div>
                  <div className="battlefield-overlay">
                    {`${viewedPlayer.username}'s Battlefield`}
                  </div>
                </Zone>
              </div>
            </div>

            {/* Right Column */}
            <div className="right-column">
              {/* Viewed Player's Zones */}
              <div className="active-player-zones">
                <div className="zone-row">
                  {/* Graveyard */}
                  <Zone
                    zoneName="graveyard"
                    onDropCard={handleDropCard}
                    isOwnZone={isOwnView}
                    onZoneClick={() => handleZoneClick('graveyard', viewedPlayer.graveyard, viewedPlayer)}
                  >
                    <p>Graveyard: {viewedPlayer.graveyard.length}</p>
                    <div className="zone-cards">
                      {viewedPlayer.graveyard.slice(-1).map((card) => (
                        <div key={card.id} className="card">
                          <Card
                            cardId={card.cardId}
                            uniqueId={card.id}
                            cardImage={getCardImage(card, viewedPlayer.flippedCards?.includes(card.id))}
                            fromZone="graveyard"
                            isOwnCard={false}
                            isFlipped={viewedPlayer.flippedCards?.includes(card.id)}
                            onShowPreview={handleShowPreview} // Add these two props
                            onHidePreview={handleHidePreview} // to all Card instances
                          />
                        </div>
                      ))}
                    </div>
                  </Zone>
                  {/* Exile */}
                  <Zone
                    zoneName="exile"
                    onDropCard={handleDropCard}
                    isOwnZone={isOwnView}
                    onZoneClick={() => handleZoneClick('exile', viewedPlayer.exile, viewedPlayer)}
                  >
                    <p>Exile: {viewedPlayer.exile.length}</p>
                    <div className="zone-cards">
                      {viewedPlayer.exile.slice(-1).map((card) => (
                        <div key={card.id} className="card">
                          <Card
                            cardId={card.cardId}
                            uniqueId={card.id}
                            cardImage={getCardImage(card, viewedPlayer.flippedCards?.includes(card.id))}
                            fromZone="exile"
                            isOwnCard={false}
                            isFlipped={viewedPlayer.flippedCards?.includes(card.id)}
                            onShowPreview={handleShowPreview} // Add these two props
                            onHidePreview={handleHidePreview} // to all Card instances
                          />
                        </div>
                      ))}
                    </div>
                  </Zone>
                </div>

                {/* Library */}
                <Zone
                  zoneName="library"
                  onDropCard={handleDropCard}
                  isOwnZone={isOwnView}
                  className="library-zone"
                >
                  <p className="mb-0 mt-1">
                    Library: {viewedPlayer.library.length} cards
                  </p>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {viewedPlayer.library.length > 0 && viewedPlayer.revealedTopCards?.includes(viewedPlayer.library[0].id) ? (
                      <img
                        src={`https://api.scryfall.com/cards/${viewedPlayer.library[0].cardId}?format=image&version=border_crop`}
                        alt={`Card ${viewedPlayer.library[0].cardId}`}
                        className="recard-image"
                        onMouseEnter={() => handleShowPreview(`https://api.scryfall.com/cards/${viewedPlayer.library[0].cardId}?format=image&version=border_crop`, 'center')}
                        onMouseLeave={handleHidePreview}
                      />
                    ) : (
                      <img
                        src="https://i.imgur.com/eKG7owo.png"
                        alt="REcard"
                        className="recard-image"
                      />
                    )}
                  </div>
                </Zone>
                {/* Command Zone */}
                <Zone
                  zoneName="commandZone"
                  onDropCard={handleDropCard}
                  isOwnZone={isOwnView}
                >
                  <div style={{ position: 'relative' }}>
                    <img
                      src="https://i.imgur.com/5FkcOq1.png"
                      alt="Command Zone"
                      style={{
                        width: '20px',
                        height: '20px',
                        position: 'absolute',
                        top: '8px',
                        left: '8px',
                        zIndex: 1,
                      }}
                    />
                  </div>
                  <div className="zone-cards">
                    {viewedPlayer.commandZone.map((card) => (
                      <div key={card.id} className="card">
                        <Card
                          cardId={card.cardId}
                          uniqueId={card.id}
                          cardImage={getCardImage(card, viewedPlayer.flippedCards?.includes(card.id))}
                          fromZone="commandZone"
                          isOwnCard={false}
                          isFlipped={viewedPlayer.flippedCards?.includes(card.id)}
                          onShowPreview={handleShowPreview} // Add these two props
                          onHidePreview={handleHidePreview} // to all Card instances
                        />
                      </div>
                    ))}
                  </div>
                </Zone>
              </div>
            </div>
          </div>

          {/* Lower Half */}
          <div className="lower-half">
            {/* Left Column */}
            <div className="left-column">
              {/* Controls */}
              <div className="controls">
                {/* Row 1: HP */}
                <div className="control-row">
                  <div className="hp-control">
                    <h2 className="mb-0">HP</h2>
                    <div className="hp-adjust">
                      <button 
                        className="hp-btn" 
                        onClick={() => handleAdjustLife(-1)}                         
                        onContextMenu={(e) => {
                          // Filter out dummy player and check if there are real players
                          if (otherPlayers.filter(player => player.userId !== 'dummy').length > 0) {
                            e.preventDefault(); // Prevent default right-click menu
                            e.stopPropagation();
                            const rect = e.currentTarget.getBoundingClientRect();
                            setAnchorPoint({
                              x: rect.left + rect.width / 2,
                              y: rect.top + rect.height / 2,
                            });
                            setOpenCommanderDmgAdd(true);
                          }
                        }}
                      >
                        <h2>-</h2>
                      </button>
                      {otherPlayers.filter(player => player.userId !== 'dummy').length > 0 && (
                        <ControlledMenu
                          anchorPoint={anchorPoint}
                          onClose={() => setOpenCommanderDmgAdd(false)}
                          state={openCommanderDmgAdd ? 'open' : 'closed'}
                          direction="right"
                          align="center"
                          portal={true}
                          menuClassName="battlefield-card-menu"
                        >
                          {otherPlayers
                            .filter(player => player.userId !== 'dummy')
                            .map((otherPlayer) => {
                              return (
                                <MenuItem key={otherPlayer.userId}>
                                  <div className="commander-dmg-input">
                                    <p className="pb-1 mb-0">{otherPlayer.username} CD</p>
                                    <input
                                      className="commander-dmg-inputBox"
                                      type="number"
                                      min="1"
                                      onClick={(e) => e.stopPropagation()}
                                      onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                          const amount = parseInt(e.target.value);
                                          if (amount > 0) {
                                            handleCommanderDmg(otherPlayer, amount);
                                            e.target.value = '';
                                            setOpenCommanderDmgAdd(false);
                                          }
                                        }
                                      }}
                                      placeholder="Enter amount"
                                    />
                                  </div>
                                </MenuItem>
                              );
                            })}
                            <MenuItem>
                              <div className="lose-x-life">
                              <p className="pb-1 mb-0">Lose X Life</p>
                                <input
                                  className="lose-x-life-input"
                                  type="number"
                                  min="1"
                                  onClick={(e) => e.stopPropagation()}
                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                      const amount = parseInt(e.target.value);
                                      if (amount > 0) {
                                        handleAdjustLife(-amount);
                                        e.target.value = '';
                                      }
                                    }
                                  }}
                                  placeholder="Enter amount"
                                />
                              </div>
                            </MenuItem>
                        </ControlledMenu>
                      )}
                      <h2 className="mx-1">{currentPlayer.lifeTotal}</h2>
                      <button 
                        className="hp-btn" 
                        onClick={() => handleAdjustLife(1)}                         
                        onContextMenu={(e) => {
                          // Filter out dummy player and check if there are real players
                          if (otherPlayers.filter(player => player.userId !== 'dummy').length > 0) {
                            e.preventDefault(); // Prevent default right-click menu
                            e.stopPropagation();
                            const rect = e.currentTarget.getBoundingClientRect();
                            setAnchorPoint({
                              x: rect.left + rect.width / 2,
                              y: rect.top + rect.height / 2,
                            });
                            setOpenCommanderDmgRemove(true);
                          }
                        }}
                      >
                        <h2>+</h2>
                      </button>
                      {otherPlayers.filter(player => player.userId !== 'dummy').length > 0 && (
                        <ControlledMenu
                          anchorPoint={anchorPoint}
                          onClose={() => setOpenCommanderDmgRemove(false)}
                          state={openCommanderDmgRemove ? 'open' : 'closed'}
                          direction="right"
                          align="center"
                          portal={true}
                          menuClassName="life-change-menu"
                        >
                          {otherPlayers
                            .filter(player => player.userId !== 'dummy')
                            .map((otherPlayer) => {
                              return (
                                <MenuItem key={otherPlayer.userId}>
                                  <div className="commander-dmg-input">
                                    <p className="pb-1 mb-0">{otherPlayer.username} CD</p>
                                    <input
                                      className="commander-dmg-inputBox"
                                      type="number"
                                      min="1"
                                      onClick={(e) => e.stopPropagation()}
                                      onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                          const amount = parseInt(e.target.value);
                                          if (amount > 0) {
                                            // Pass negative amount to undo commander damage
                                            handleCommanderDmg(otherPlayer, -amount);
                                            e.target.value = '';
                                            setOpenCommanderDmgRemove(false);
                                          }
                                        }
                                      }}
                                      placeholder="Enter amount"
                                    />
                                  </div>
                                </MenuItem>
                              );
                            })}
                            <MenuItem>
                              <div className="gain-x-life">
                              <p className="pb-1 mb-0">Gain X Life</p>
                                <input
                                  className="gain-x-life-input"
                                  type="number"
                                  min="1"
                                  onClick={(e) => e.stopPropagation()}
                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                      const amount = parseInt(e.target.value);
                                      if (amount > 0) {
                                        handleAdjustLife(amount);
                                        e.target.value = '';
                                      }
                                    }
                                  }}
                                  placeholder="Enter amount"
                                />
                              </div>
                            </MenuItem>
                        </ControlledMenu>
                      )}
                    </div>
                  </div>
                </div>

                {/* Row 2: Counters */}
                <div className="control-row mt-1">
                  <button className="control-btn" onClick={() => handlePlayerInfoClick(user.sub)}>Player Info</button>
                  <Menu
                    menuButton={
                      <button className="control-btn">Hand Options</button>
                    }
                    direction="left"
                    align="center"
                    portal={true}
                  >
                    <SubMenu label="Reveal Hand To" direction="right" offsetX={10}>
                      {gameState.players.filter((player) => player.userId !== user.sub).map((player) => (
                        <MenuItem
                          key={player.userId}
                          onClick={() => handleRevealHandTo(user.sub, player.userId)}
                        >
                          {player.username}
                        </MenuItem>
                      ))}
                    </SubMenu>
                    <SubMenu label="Send Random Card To" direction="right" offsetX={10}>
                      <MenuItem onClick={() => handleSendRandomCardTo('graveyard')}>
                        Graveyard
                      </MenuItem>
                      <MenuItem onClick={() => handleSendRandomCardTo('exile')}>
                        Exile
                      </MenuItem>
                      <MenuItem onClick={() => handleSendRandomCardTo('battlefield')}>
                        Battlefield Face Down
                      </MenuItem>
                    </SubMenu>
                    <SubMenu label="Send Hand To" direction="right" offsetX={10}>
                      <MenuItem onClick={() => handleSendHandTo('graveyard')}>
                        Graveyard
                      </MenuItem>
                      <MenuItem onClick={() => handleSendHandTo('exile')}>
                        Exile
                      </MenuItem>
                    </SubMenu>
                  </Menu>
                </div>

                {/* Row 3: Misc Buttons 1-2 */}
                <div className="control-row">
                  <button className="control-btn" onClick={handleUntapAll}>
                    Untap All
                  </button>
                  <button className="control-btn" onClick={() => setIsTokenModalOpen(true)}>
                    Make Token
                  </button>
                </div>

                {/* Row 4: Misc Buttons 3-4 */}
                <div className="control-row">
                  <Menu
                    menuButton={
                      <button className="control-btn">Roll a die</button>
                    }
                    direction="right"
                    align="center"
                    portal={true}
                  >
                    <MenuItem>
                      <div className="custom-roll">
                        <input
                          className="roll-x-input"
                          type="number"
                          min="1"
                          onClick={(e) => e.stopPropagation()}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              const sides = parseInt(e.target.value);
                              if (sides > 0) {
                                handleRollDie(sides, user['https://replacementeffect.com/username']);
                                e.target.value = ''; // Clear the input after rolling
                              }
                            }
                          }}
                          placeholder="Enter sides"
                        />
                      </div>
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleRollDie(4, user['https://replacementeffect.com/username'])
                      }
                    >
                      Roll D4
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleRollDie(6, user['https://replacementeffect.com/username'])
                      }
                    >
                      Roll D6
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleRollDie(8, user['https://replacementeffect.com/username'])
                      }
                    >
                      Roll D8
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleRollDie(10, user['https://replacementeffect.com/username'])
                      }
                    >
                      Roll D10
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleRollDie(12, user['https://replacementeffect.com/username'])
                      }
                    >
                      Roll D12
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleRollDie(20, user['https://replacementeffect.com/username'])
                      }
                    >
                      Roll D20
                    </MenuItem>
                  </Menu>
                  <button className="control-btn" onClick={() => setIsCounterModalOpen(true)}>
                    Add Counter
                  </button>
                </div>

                {/* Row 5: Change Deck and Settings */}
                <div className="control-row">
                  <button className="control-btn" onClick={handleChangeDeck}>Change Deck</button>
                  <button className="control-btn" onClick={() => setIsSettingsModalOpen(true)}>Settings</button>
                </div>

                {/* Row 6: Leave Room */}
                <div className="control-row mt-3">
                  <button className="control-btn" onClick={handleLeaveRoom}>
                    Leave Room
                  </button>
                </div>
              </div>
            </div>

            {/* Middle Column */}
            <div className="middle-column">
              {/* Your Battlefield */}
              <div className="your-battlefield">
                <BattlefieldZone
                  zoneName="battlefield"
                  onDropCard={handleDropCard}
                  isOwnZone={true}
                >
                  <div className="battlefield-cards">
                    {/* Render un-fused counters */}
                    {counters
                      .filter((counter) => !counter.isFused && counter.ownerId === user.sub)
                      .map((counter) => (
                        <div
                          key={counter.id}
                          style={{
                            left: getCounterPosition(counter).x,
                            top: getCounterPosition(counter).y,
                            cursor: 'default',
                            zIndex: 5000,
                          }}
                        >
                          <Counter
                            counter={counter}
                            onDelete={handleDeleteCounter}
                            onFuseToggle={handleFuseToggle}
                            onIncrement={handleIncrementCounter}
                            onDecrement={handleDecrementCounter}
                            isOwnCounter={counter.ownerId === user.sub}
                            isFusing={fuseCounterId === counter.id}
                            isFusable={counter.isFusable}
                          />
                        </div>
                      ))}
                    {currentPlayer.battlefield.map((card) => (
                      <Draggable
                        key={card.id}
                        className="draggables"
                        bounds=".battlefield-zone"
                        scale={1}
                        onStart={() => handleZindex(card.id)}
                        onMove={handleDragStart}
                        onEnd={(e, data) => handleDragEnd(card.id, data.x, data.y)}
                        x={getCardPosition(card.id).x}
                        y={getCardPosition(card.id).y}
                        data-card-id={card.id}
                        disabled={isCtrlPressed}
                        style={{
                          position: 'absolute',
                          cursor: 'grab',
                          background: 'transparent',
                          transformOrigin: 'center',
                          zIndex: '5000', // Add this line to set initial z-index
                          pointerEvents: isCtrlPressed ? 'none' : 'auto',
                        }}
                        onStop={(e, data) => {
                          handleDragEnd(card.id, data.x, data.y);
                        }}
                        // Add touch event handlers for mobile support
                        onTouchStart={() => handleZindex(card.id)} // Handle touch start
                        onTouchMove={(e) => {
                          const touch = e.touches[0];
                          const deltaX = touch.clientX - getCardPosition(card.id).x;
                          const deltaY = touch.clientY - getCardPosition(card.id).y;

                          // Set a threshold for movement
                          const threshold = 10; // Adjust this value as needed
                          if (Math.abs(deltaX) > threshold || Math.abs(deltaY) > threshold) {
                            handleDragStart(touch); // Pass touch event to drag start
                          }
                        }}
                        onTouchEnd={(e) => {
                          const touch = e.changedTouches[0];
                          handleDragEnd(card.id, touch.clientX, touch.clientY); // Handle touch end
                        }}
                      >
                        <div
                          className="card"
                          style={{ display: card ? 'inline' : 'none' }}
                          onContextMenu={(e) => {
                            if(!isShiftPressed) {
                              if (
                                typeof document.hasFocus === 'function' &&
                                !document.hasFocus()
                              )
                                return;

                              e.preventDefault();
                              const cardElement = e.currentTarget;
                              const rect = cardElement.getBoundingClientRect();
                              const centerX = rect.left + rect.width / 2;
                              const centerY = rect.top + rect.height / 2;

                              setAnchorPoint({ x: centerX, y: centerY });
                              
                              setOpenMenuCardId(card.id);
                            }
                          }}
                          onClick={() => {
                            if (fuseCounterId) {
                              handleFuseCounterToCard(card.id);
                            }
                          }}
                        >
                          {/* Render the Card component independently */}
                          <Card
                            cardId={card.cardId}
                            uniqueId={card.id}
                            cardImage={getCardImage(card, currentPlayer.flippedCards?.includes(card.id))}
                            fromZone="battlefield"
                            onCardAction={handleCardAction}
                            isOwnCard={true}
                            isOnBattlefield={true}
                            isDragging={isDragging}
                            onClick={() => {
                              if (isCtrlPressed) {
                                handleCardTap(card.id);
                              }
                            }}
                            onTouchEnd={(e) => {
                              const touch = e.changedTouches[0];
                              const currentTime = new Date().getTime();
                              if (currentTime - lastTapTimeRef.current < 300) { // 300ms threshold for double-tap
                                handleCardTap(card.id); // Call the tap function on double-tap
                              }
                              lastTapTimeRef.current = currentTime; // Update last tap time
                            }}
                            isTapped={currentPlayer.tappedCards?.includes(card.id)}
                            isFlipped={currentPlayer.flippedCards?.includes(card.id)}
                            onShowPreview={handleShowPreview} // Add these two props
                            onHidePreview={handleHidePreview} // to all Card instances
                          />
                          {counters
                            .filter((counter) => counter.fusedCardId === card.id)
                            .map((counter) => (
                              <Counter
                                key={counter.id}
                                counter={counter}
                                onDelete={handleDeleteCounter}
                                onFuseToggle={handleFuseToggle}
                                onIncrement={handleIncrementCounter}
                                onDecrement={handleDecrementCounter}
                                isOwnCounter={counter.ownerId === user.sub}
                                isFusing={fuseCounterId === counter.id}
                                style={getFusedCounterPosition(counter, card.id, counters)}
                              />
                          ))}
                          {/* Separate ControlledMenu */}
                          <ControlledMenu
                            anchorPoint={anchorPoint}
                            onClose={() => setOpenMenuCardId(null)} // Clear the open menu card id
                            state={openMenuCardId === card.id ? 'open' : 'closed'} // Only open for the specific card
                            direction="right"
                            align="center"
                            portal={true}
                            menuClassName="battlefield-card-menu"
                          >
                            <SubMenu label="Move To" direction="right" offsetX={10}>
                              <MenuItem
                                onClick={() => {
                                  handleCardAction('moveToHand', card.id, 'battlefield');
                                  setOpenMenuCardId(null); // Close menu after action
                                }}
                              >
                                Move to Hand
                              </MenuItem>
                              <MenuItem
                                onClick={() =>
                                  handleCardAction('moveToGraveyard', card.id, 'battlefield')
                                }
                              >
                                Move To Graveyard
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  handleCardAction('moveToExile', card.id, 'battlefield');
                                  setOpenMenuCardId(null); // Just close the context menu, keep zone menu open
                                }}
                              >
                                Move To Exile
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  handleCardAction('moveToLibrary', card.id, 'battlefield');
                                  setOpenMenuCardId(null); // Just close the context menu, keep zone menu open
                                }}
                              >
                                Move To Library
                              </MenuItem>
                              {currentPlayer.commanderCardIds?.includes(card.cardId) && (
                                <MenuItem
                                  onClick={() => {
                                  handleCardAction('moveToCommandZone', card.id, 'battlefield');
                                  setOpenMenuCardId(null);
                                }}
                                >
                                  Move to Command Zone
                                </MenuItem>
                              )}
                            </SubMenu>
                            <MenuItem onClick={() => handleCloneCard(card.id, currentPlayer.flippedCards?.includes(card.id))}>
                              Clone
                            </MenuItem>
                            <MenuItem onClick={() => handleCardFlip(card.id)}>
                              {currentPlayer.flippedCards?.includes(card.id)
                                ? 'Unflip'
                                : 'Flip'}
                            </MenuItem>
                            {currentPlayer.flippedCards?.includes(card.id) && (
                              <SubMenu label="Reveal To" direction="right" offsetX={10}>
                                {gameState.players.map((player) => (
                                  <MenuItem
                                    key={player.userId}
                                    onClick={() => handleRevealToPlayer(card.id, player.userId)}
                                  >
                                    {player.username}
                                  </MenuItem>
                                ))}
                              </SubMenu>
                            )}
                          </ControlledMenu>
                        </div>
                      </Draggable>
                    ))}
                  </div>
                </BattlefieldZone>
              </div>
            </div>

            {/* Right Column */}
            <div className="right-column">
              {/* Your Zones */}
              <div className="your-zones">
                {/* Command Zone */}
                <Zone
                  zoneName="commandZone"
                  onDropCard={handleDropCard}
                  isOwnZone={true}
                >
                  <div style={{ position: 'relative' }}>
                    <img
                      src="https://i.imgur.com/5FkcOq1.png"
                      alt="Command Zone"
                      style={{
                        width: '20px',
                        height: '20px',
                        position: 'absolute',
                        top: '8px',
                        left: '8px',
                        zIndex: 1,
                      }}
                    />
                  </div>
                  <div className="zone-cards">
                    {currentPlayer.commandZone.map((card) => (
                      <div key={card.id} className="card">
                        <Menu
                          menuButton={(menuButtonProps) => (
                            <Card
                              ref={menuButtonProps.ref}
                              cardId={card.cardId}
                              uniqueId={card.id}
                              cardImage={getCardImage(card, currentPlayer.flippedCards?.includes(card.id))}
                              fromZone="commandZone"
                              onCardAction={handleCardAction}
                              isOwnCard={true}
                              isOnBattlefield={false}
                              isFlipped={currentPlayer.flippedCards?.includes(card.id)}
                              onShowPreview={handleShowPreview} // Add these two props
                              onHidePreview={handleHidePreview} // to all Card instances
                              {...menuButtonProps}
                            />
                          )}
                          direction="right"
                          align="center"
                          portal={true}
                        >
                          <MenuItem
                            onClick={() =>
                              handleCardAction('moveToHand', card.id, 'commandZone')
                            }
                          >
                            Move to Hand
                          </MenuItem>
                        </Menu>
                      </div>
                    ))}
                  </div>
                </Zone>

                {/* Library */}
                <Zone
                  zoneName="library"
                  onDropCard={handleDropCard}
                  isOwnZone={true}
                  className="library-zone"
                >
                  <p className="mb-0 mt-1">
                    Library: {currentPlayer.library.length} cards
                  </p>
                  {isCtrlPressed ? (
                    <img
                      src={currentPlayer.library.length > 0 && currentPlayer.revealedTopCards?.includes(currentPlayer.library[0].id)
                        ? `https://api.scryfall.com/cards/${currentPlayer.library[0].cardId}?format=image&version=border_crop`
                        : "https://i.imgur.com/eKG7owo.png"
                      }
                      alt="REcard"
                      className="recard-image"
                      style={{ cursor: 'pointer' }}
                      onMouseEnter={() => currentPlayer.library.length > 0 && currentPlayer.revealedTopCards?.includes(currentPlayer.library[0].id)
                        ? handleShowPreview(`https://api.scryfall.com/cards/${currentPlayer.library[0].cardId}?format=image&version=border_crop`, 'center')
                        : null
                      }
                      onMouseLeave={handleHidePreview}
                      onClick={handleDrawCard}
                    />
                  ) : (
                  <Menu
                    menuButton={
                      <img
                        src={currentPlayer.library.length > 0 && currentPlayer.revealedTopCards?.includes(currentPlayer.library[0].id)
                          ? `https://api.scryfall.com/cards/${currentPlayer.library[0].cardId}?format=image&version=border_crop`
                          : "https://i.imgur.com/eKG7owo.png"
                        }
                        alt="REcard"
                        className="recard-image"
                        style={{ cursor: 'context-menu' }}
                        onMouseEnter={() => currentPlayer.library.length > 0 && currentPlayer.revealedTopCards?.includes(currentPlayer.library[0].id)
                          ? handleShowPreview(`https://api.scryfall.com/cards/${currentPlayer.library[0].cardId}?format=image&version=border_crop`, 'center')
                          : null
                        }
                        onMouseLeave={handleHidePreview}
                        onClick={() => {
                          if (isCtrlPressed) {
                            handleDrawCard();
                          }
                        }}
                      />
                    }
                    direction="left"
                    align="center"
                    portal={true}
                    overflow={overflow}
                  >
                    <MenuItem onClick={handleDrawCard}>Draw Card</MenuItem>
                    <SubMenu label="Draw X" direction="right" offsetX={10}>
                      <MenuItem>
                        <div className="custom-draw">
                          <input
                            className="draw-x-input"
                            type="number"
                            min="1"
                            onClick={(e) => e.stopPropagation()}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                const amount = parseInt(e.target.value);
                                if (amount > 0) {
                                  handleDrawMultipleCards(amount);
                                  e.target.value = '';
                                }
                              }
                            }}
                            placeholder="Enter amount"
                          />
                        </div>
                      </MenuItem>
                    </SubMenu>
                    <SubMenu label="Move To" direction="right" offsetX={10}>
                      <MenuItem onClick={() => handleMoveTo('graveyard', false)}>Graveyard</MenuItem>
                      <MenuItem onClick={() => handleMoveTo('exile', false)}>Exile</MenuItem>
                      <MenuItem onClick={() => handleMoveTo('bottom', false)}>Bottom</MenuItem>
                      <MenuItem onClick={() => handleMoveTo('battlefield', false)}>Battlefield</MenuItem>
                      <MenuItem>
                      <div className="x-down">
                          <input
                            className="move-x-down-input"
                            type="number"
                            min="1"
                            onClick={(e) => e.stopPropagation()}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                const amount = parseInt(e.target.value);
                                if (amount > 0) {
                                  handleMoveXDown(amount);
                                  e.target.value = '';
                                }
                              }
                            }}
                            placeholder="Move X Down"
                          />
                        </div>
                      </MenuItem>
                    </SubMenu>
                    <SubMenu label="Move X To" direction="right" offsetX={10}>
                      <MenuItem>
                        <div className="x-to">
                          Graveyard
                          <input
                            className="move-x-to-input"
                            type="number"
                            min="1"
                            onClick={(e) => e.stopPropagation()}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                const amount = parseInt(e.target.value);
                                if (amount > 0) {
                                  handleMoveXTo('graveyard', amount);
                                  e.target.value = '';
                                }
                              }
                            }}
                            placeholder="Move X To Graveyard"
                          />
                        </div>
                      </MenuItem>
                      <MenuItem>
                        <div className="x-to">
                          Exile
                          <input
                            className="move-x-to-input"
                            type="number"
                            min="1"
                            onClick={(e) => e.stopPropagation()}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                const amount = parseInt(e.target.value);
                                if (amount > 0) {
                                  handleMoveXTo('exile', amount);
                                  e.target.value = '';
                                }
                              }
                            }}
                            placeholder="Move X To Exile"
                          />
                        </div>
                      </MenuItem>
                      <MenuItem>
                        <div className="x-to">
                          Battlefield
                          <input
                            className="move-x-to-input"
                            type="number"
                            min="1"
                            onClick={(e) => e.stopPropagation()}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                const amount = parseInt(e.target.value);
                                if (amount > 0) {
                                  handleMoveXTo('battlefield', amount);
                                  e.target.value = '';
                                }
                              }
                            }}
                            placeholder="Move X To Battlefield"
                          />
                        </div>
                      </MenuItem>
                    </SubMenu>
                    <SubMenu label="Move Bottom To" direction="right" offsetX={10}>
                      <MenuItem onClick={() => handleMoveTo('graveyard', true)}>Graveyard</MenuItem>
                      <MenuItem onClick={() => handleMoveTo('exile', true)}>Exile</MenuItem>
                      <MenuItem onClick={() => handleMoveTo('battlefield', true)}>Battlefield</MenuItem>
                      <MenuItem onClick={() => handleMoveTo('hand', true)}>Hand</MenuItem>
                    </SubMenu>
                    <MenuItem onClick={() => handleZoneClick('library', currentPlayer.library, currentPlayer)}>Search Library</MenuItem>
                    <SubMenu label="Mill X" direction="right" offsetX={10}>
                      <MenuItem>
                        <div className="custom-draw">
                          <input
                            className="draw-x-input"
                            type="number"
                            min="1"
                            onClick={(e) => e.stopPropagation()}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                const amount = parseInt(e.target.value);
                                if (amount > 0) {
                                  handleMillMultipleCards(amount);
                                  e.target.value = '';
                                }
                              }
                            }}
                            placeholder="Enter amount"
                          />
                        </div>
                      </MenuItem>
                    </SubMenu>
                    <MenuItem onClick={handleRevealTopCard}>Reveal/Unreveal Top Card</MenuItem>
                    <SubMenu label="Scry X" direction="right" offsetX={10}>
                      <MenuItem>
                        <div className="custom-draw">
                          <input
                            className="draw-x-input"
                            type="number"
                            min="1"
                            onClick={(e) => e.stopPropagation()}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                const amount = parseInt(e.target.value);
                                if (amount > 0) {
                                  handleScry(amount);
                                  e.target.value = '';
                                }
                              }
                            }}
                            placeholder="Enter amount"
                          />
                        </div>
                      </MenuItem>
                    </SubMenu>
                    <SubMenu label="Surveil X" direction="right" offsetX={10}>
                      <MenuItem>
                        <div className="custom-draw">
                          <input
                            className="draw-x-input"
                            type="number"
                            min="1"
                            onClick={(e) => e.stopPropagation()}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                const amount = parseInt(e.target.value);
                                if (amount > 0) {
                                  handleSurveil(amount);
                                  e.target.value = '';
                                }
                              }
                            }}
                            placeholder="Enter amount"
                          />
                        </div>
                      </MenuItem>
                    </SubMenu>
                    <MenuItem onClick={handleShuffle}>Shuffle</MenuItem>
                    <MenuItem
                      value={isTopFlipped ? 'Unflip Top' : 'Keep Top Flipped'}
                      onClick={(e) => {
                        handleKeepTopFlipped()
                        e.keepOpen = true;
                      }}
                    >
                      {isTopFlipped ? 'Unflip Top' : 'Keep Top Flipped'}
                    </MenuItem>
                  </Menu>
                  )}
                </Zone>

                <div className="zone-row">
                  {/* Graveyard */}
                  <Zone
                    zoneName="graveyard"
                    onDropCard={handleDropCard}
                    isOwnZone={true}
                    onZoneClick={() =>
                      handleZoneClick('graveyard', currentPlayer.graveyard, currentPlayer)
                    }
                  >
                    <p>Graveyard: {currentPlayer.graveyard.length}</p>
                    <div className="zone-cards">
                      {currentPlayer.graveyard.slice(-1).map((card) => (
                        <div key={card.id} className="card">
                          <Card
                            cardId={card.cardId}
                            uniqueId={card.id}
                            cardImage={getCardImage(card, currentPlayer.flippedCards?.includes(card.id))}
                            fromZone="graveyard"
                            isOwnCard={true} // Set to true
                            isFlipped={currentPlayer.flippedCards?.includes(card.id)}
                            onShowPreview={handleShowPreview}
                            onHidePreview={handleHidePreview}
                          />
                        </div>
                      ))}
                    </div>
                  </Zone>
                  {/* Exile */}
                  <Zone
                    zoneName="exile"
                    onDropCard={handleDropCard}
                    isOwnZone={true}
                    onZoneClick={() => handleZoneClick('exile', currentPlayer.exile, currentPlayer)}
                  >
                    <p>Exile: {currentPlayer.exile.length}</p>
                    <div className="zone-cards">
                      {currentPlayer.exile.slice(-1).map((card) => (
                        <div key={card.id} className="card">
                          <Card
                            cardId={card.cardId}
                            uniqueId={card.id}
                            cardImage={getCardImage(card, currentPlayer.flippedCards?.includes(card.id))}
                            fromZone="exile"
                            isOwnCard={true} // Set to true
                            isFlipped={currentPlayer.flippedCards?.includes(card.id)}
                            onShowPreview={handleShowPreview}
                            onHidePreview={handleHidePreview}
                          />
                        </div>
                      ))}
                    </div>
                  </Zone>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Hand Area */}
        <div className="hand-area">
          {/* Left Arrow and Count */}
          <div className="hand-nav-container">
            <div className="cards-count">{cardsToLeft > 0 ? cardsToLeft : ''}</div>
            <button
              className="hand-nav-button hand-nav-left"
              onClick={() =>
                setHandStartIndex((prevIndex) => Math.max(0, prevIndex - 1))
              }
              disabled={handStartIndex === 0}
            >
              &#9664;
            </button>
          </div>

          <div className="hand-cards">
            {currentPlayer.hand
              .slice(handStartIndex, handStartIndex + 7)
              .map((card) => (
                <div key={card.id} className="hand-card">
                  <Menu
                    menuButton={(menuButtonProps) => (
                      <Card
                        ref={menuButtonProps.ref}
                        cardId={card.cardId}
                        uniqueId={card.id}
                        cardImage={getCardImage(card, currentPlayer.flippedCards?.includes(card.id))}
                        fromZone="hand"
                        onCardAction={handleCardAction}
                        isOwnCard={true}
                        isFlipped={currentPlayer.flippedCards?.includes(card.id)}
                        onShowPreview={handleShowPreview}
                        onHidePreview={handleHidePreview}
                        {...menuButtonProps}
                      />
                    )}
                    direction="right"
                    align="center"
                    portal={true}
                  >
                    <MenuItem
                      onClick={() => handleCardFlip(card.id)}
                    >
                      Flip
                    </MenuItem>
                    {/* Add more actions as needed */}
                  </Menu>
                </div>
              ))}
          </div>

          {/* Right Arrow and Count */}
          <div className="hand-nav-container">
            <div className="cards-count">
              {cardsToRight > 0 ? cardsToRight : ''}
            </div>
            <button
              className="hand-nav-button hand-nav-right"
              onClick={() => {
                const maxIndex = Math.max(0, currentPlayer.hand.length - 7);
                setHandStartIndex((prevIndex) => Math.min(maxIndex, prevIndex + 1));
              }}
              disabled={handStartIndex >= currentPlayer.hand.length - 7}
            >
              &#9654;
            </button>
          </div>
        </div>

        {/* Update the preview overlay */}
        {previewCard && (
          <div
            className={`card-preview-overlay ${
              isPreviewOnRightSide === 'center'
                ? 'center'
                : isPreviewOnRightSide
                ? 'left-side'
                : 'right-side'
            }`}
          >
            <img
              src={previewCard}
              alt="Card Preview"
              className="card-preview-image"
            />
          </div>
        )}
        <Modal
          isOpen={isTokenModalOpen}
          onRequestClose={() => setIsTokenModalOpen(false)}
          contentLabel="Create Token"
          className="token-modal text-center"
          overlayClassName="token-modal-overlay"
        >
          <h2 className='mb-4'>Create a Token</h2>
          <input
            type="text"
            placeholder="Type the name of the token you're looking for, then press 'Enter'"
            value={tokenSearchTerm}
            onChange={(e) => setTokenSearchTerm(e.target.value)} // Only update the search term
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleTokenSearch(); // Call the search function
              }
            }}
            className="token-search-input"
          />
          <div className="token-search-results">
          {tokenSearchResults.map((card) => {
            let imageUrl = 'default_image_url'; // Default image URL
            if (card.image_uris && card.image_uris.border_crop) {
              imageUrl = card.image_uris.border_crop;
            } else if (card.card_faces && card.card_faces[0].image_uris.border_crop) {
              imageUrl = card.card_faces[0].image_uris.border_crop;
            }

            return (
              <div
                key={card.id}
                className="token-result-card"
                onClick={() => handleTokenSelect(card)}
              >
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt={card.name}
                    className="token-result-image"
                  />
                ) : (
                  <div className="no-image-placeholder">
                    <p>No image available</p>
                  </div>
                )}
                <p>{card.name}</p>
              </div>
            );
          })}
          </div>
          <button   
            onClick={() => {
              setIsTokenModalOpen(false);
              setTokenSearchTerm(''); // Clear the input field
            }}
            className="token-modal-close">
            Close
          </button>
        </Modal>
        {/* Scry Modal */}
        {isScryModalOpen && (
          <div className="zone-menu-overlay text-center" onClick={(e) => {
            // Prevent closing if clicking overlay
            e.stopPropagation();
          }}>
            <div className="zone-menu-content" onClick={(e) => e.stopPropagation()}>
              <h2 className="mb-4">Scry</h2>
              <button 
                className="scry-randomized-button" 
                style={{ backgroundColor: isBottomRandomized ? '#885e77' : 'white' }}
                onClick={() => setIsBottomRandomized(!isBottomRandomized)}
              >
                Randomize Bottom Cards
              </button>              
              <div className="scry-sections">
                {/* Available Cards Section */}
                <div className="scry-section">
                  <h3>Available Cards</h3>
                  <div className="scry-cards-container">
                    {scryCards.map((card) => (
                      <div key={card.id} className="card scry-card" style={{ position: 'relative' }}>
                        <Card
                          cardId={card.cardId}
                          uniqueId={card.id}
                          cardImage={getCardImage(card, currentPlayer.flippedCards?.includes(card.id))}
                          isOwnCard={true}
                          isFlipped={currentPlayer.flippedCards?.includes(card.id)}
                          onShowPreview={null}
                          onHidePreview={null}
                        />
                        <div className="scry-card-actions">
                          <button onClick={() => handleScryCardPlacement(card.id, 'top')}>
                            Top
                          </button>
                          <button onClick={() => handleScryCardPlacement(card.id, 'bottom')}>
                            Bottom
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Top Cards Section */}
                <div className="scry-section">
                  <h3>Top Cards</h3>
                  <div className="scry-cards-container">
                    {scryTopCards.map((card, index) => (
                      <div key={card.id} className="card scry-card" style={{ position: 'relative' }}>
                        <Card
                          cardId={card.cardId}
                          uniqueId={card.id}
                          cardImage={getCardImage(card, currentPlayer.flippedCards?.includes(card.id))}
                          isOwnCard={true}
                          isFlipped={currentPlayer.flippedCards?.includes(card.id)}
                          onShowPreview={null}
                          onHidePreview={null}
                        />
                        <div className="scry-card-actions">
                          <button onClick={() => moveCardUp(index)}>Up</button>
                          <button onClick={() => moveCardDown(index)}>Down</button>
                          <button onClick={() => moveCardBackToAvailable(card.id)}>Remove</button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Bottom Cards Section */}
                <div className="scry-section">
                  <h3>Bottom Cards</h3>
                  <div className="scry-cards-container">
                    {scryBottomCards.map((card) => (
                      <div key={card.id} className="card scry-card" style={{ position: 'relative' }}>
                        <Card
                          cardId={card.cardId}
                          uniqueId={card.id}
                          cardImage={getCardImage(card, currentPlayer.flippedCards?.includes(card.id))}
                          isOwnCard={true}
                          isFlipped={currentPlayer.flippedCards?.includes(card.id)}
                          onShowPreview={null}
                          onHidePreview={null}
                        />
                        <div className="scry-card-actions">
                          <button onClick={() => moveCardBackToAvailable(card.id)}>Remove</button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <button 
                className="scry-done-button my-3" 
                onClick={finishScry}
                disabled={scryCards.length > 0} // Disable button if there are still available cards
                style={{ 
                  opacity: scryCards.length > 0 ? 0.5 : 1,
                  cursor: scryCards.length > 0 ? 'not-allowed' : 'pointer'
                }}
              >
                Done
              </button>
            </div>
          </div>
        )}
        {/* Surveil Modal */}
        {isSurveilModalOpen && (
          <div className="zone-menu-overlay text-center" onClick={(e) => {
            // Prevent closing if clicking overlay
            e.stopPropagation();
          }}>
            <div className="zone-menu-content" onClick={(e) => e.stopPropagation()}>
              <h2 className="mb-4">Surveil</h2>
              <div className="surveil-sections">
                {/* Available Cards Section */}
                <div className="surveil-section">
                  <h3>Available Cards</h3>
                  <div className="surveil-cards-container">
                    {surveilCards.map((card) => (
                      <div key={card.id} className="card surveil-card" style={{ position: 'relative' }}>
                        <Card
                          cardId={card.cardId}
                          uniqueId={card.id}
                          cardImage={getCardImage(card, currentPlayer.flippedCards?.includes(card.id))}
                          isOwnCard={true}
                          isFlipped={currentPlayer.flippedCards?.includes(card.id)}
                          onShowPreview={null}
                          onHidePreview={null}
                        />
                        <div className="surveil-card-actions">
                          <button onClick={() => handleSurveilCardPlacement(card.id, 'top')}>
                            Top
                          </button>
                          <button onClick={() => handleSurveilCardPlacement(card.id, 'graveyard')}>
                            Graveyard
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Top Cards Section */}
                <div className="surveil-section">
                  <h3>Top Cards</h3>
                  <div className="surveil-cards-container">
                    {surveilTopCards.map((card, index) => (
                      <div key={card.id} className="card surveil-card" style={{ position: 'relative' }}>
                        <Card
                          cardId={card.cardId}
                          uniqueId={card.id}
                          cardImage={getCardImage(card, currentPlayer.flippedCards?.includes(card.id))}
                          isOwnCard={true}
                          isFlipped={currentPlayer.flippedCards?.includes(card.id)}
                          onShowPreview={null}
                          onHidePreview={null}
                        />
                        <div className="surveil-card-actions">
                          <button onClick={() => moveCardUpSurveil(index)}>Up</button>
                          <button onClick={() => moveCardDownSurveil(index)}>Down</button>
                          <button onClick={() => moveCardBackToAvailableSurveil(card.id)}>Remove</button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Bottom Cards Section */}
                <div className="surveil-section">
                  <h3>Graveyard Cards</h3>
                  <div className="surveil-cards-container">
                    {surveilGraveyardCards.map((card) => (
                      <div key={card.id} className="card surveil-card" style={{ position: 'relative' }}>
                        <Card
                          cardId={card.cardId}
                          uniqueId={card.id}
                          cardImage={getCardImage(card, currentPlayer.flippedCards?.includes(card.id))}
                          isOwnCard={true}
                          isFlipped={currentPlayer.flippedCards?.includes(card.id)}
                          onShowPreview={null}
                          onHidePreview={null}
                        />
                        <div className="surveil-card-actions">
                          <button onClick={() => moveCardBackToAvailableSurveil(card.id)}>Remove</button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <button 
                className="surveil-done-button my-3" 
                onClick={finishSurveil}
                disabled={surveilCards.length > 0} // Disable button if there are still available cards
                style={{ 
                  opacity: surveilCards.length > 0 ? 0.5 : 1,
                  cursor: surveilCards.length > 0 ? 'not-allowed' : 'pointer'
                }}
              >
                Done
              </button>
            </div>
          </div>
        )}
        {/* Zone Menu */}
        {isZoneMenuOpen && (
          <div
            className="zone-menu-overlay"
            onClick={() => {
              setIsZoneMenuOpen(false);
              if (isSearchingLibrary) {
                // After closing the library search, shuffle the library
                if (socket) {
                  socket.emit('shuffleLibrary', {
                    roomId,
                    userId: user.sub,
                  });
                }
                setIsSearchingLibrary(false);
                setSearchTerm('');
              }
            }}
          >
            <div
              className="zone-menu-content"
              onClick={(e) => e.stopPropagation()}
            >
              <h3 className="mb-3 text-center">{zoneToView.toUpperCase()}</h3>
              {zoneToView === 'graveyard' && zoneOwner.userId === currentPlayer.userId && (
                <div className="zone-menu-actions mb-3">
                  <button 
                  className="zone-action-btn"
                  onClick={() => {
                    if (socket && zoneToView === 'graveyard') {
                      socket.emit('moveAllCards', {
                        roomId,
                        userId: user.sub,
                        fromZone: 'graveyard',
                        toZone: 'library',
                        shouldShuffle: true
                      });
                      setIsZoneMenuOpen(false);
                    }
                  }}
                >
                  Shuffle into Library
                </button>
                <button 
                  className="zone-action-btn"
                  onClick={() => {
                    if (socket && zoneToView === 'graveyard') {
                      socket.emit('moveAllCards', {
                        roomId,
                        userId: user.sub,
                        fromZone: 'graveyard',
                        toZone: 'exile',
                        shouldShuffle: false
                      });
                      setIsZoneMenuOpen(false);
                    }
                  }}
                >
                  Move All to Exile
                </button>
              </div>
              )}
              <input
                type="text"
                placeholder={`Search ${zoneToView}`}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="zone-menu-search"
              />
              <div className="zone-menu-cards">
                {zoneCards
                  .filter((card) => {
                    const cardName = getCardName(card.cardId);
                    return cardName.toLowerCase().includes(searchTerm.toLowerCase());
                  })
                  .map((card) => (
                    <div
                      key={card.id}
                      className="card"
                      style={{ position: 'relative' }}
                      onContextMenu={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const rect = e.currentTarget.getBoundingClientRect();
                        setAnchorPoint({
                          x: rect.left + rect.width / 2,
                          y: rect.top + rect.height / 2,
                        });
                        setOpenMenuCardId(card.id);
                      }}
                    >
                      <Card
                        cardId={card.cardId}
                        uniqueId={card.id}
                        cardImage={getCardImage(card, zoneOwner.flippedCards?.includes(card.id))}
                        fromZone={zoneToView}
                        isOwnCard={zoneOwner.userId === currentPlayer.userId}
                        isFlipped={zoneOwner.flippedCards?.includes(card.id)}
                        onShowPreview={null}
                        onHidePreview={null}
                        className={"zone-card"}
                      />
                      <ControlledMenu
                        anchorPoint={anchorPoint}
                        onClose={() => setOpenMenuCardId(null)}
                        state={openMenuCardId === card.id ? 'open' : 'closed'}
                        direction="right"
                        align="center"
                        portal={true}
                        menuClassName="zone-card-menu"
                        className={"zone-card-menu"}
                      >
                        {/* Only allow moving cards if you own them */}
                        {zoneOwner.userId === currentPlayer.userId && (
                          <>
                            <MenuItem
                              onClick={() => {
                                handleCardAction('moveToHand', card.id, zoneToView);
                                setOpenMenuCardId(null);
                              }}
                            >
                              Move to Hand
                            </MenuItem>
                            {zoneToView !== 'graveyard' && (
                              <MenuItem
                                onClick={() => {
                                  handleCardAction('moveToGraveyard', card.id, zoneToView);
                                  setOpenMenuCardId(null);
                                }}
                              >
                                Move To Graveyard
                              </MenuItem>
                            )}
                            {zoneToView !== 'exile' && (
                              <MenuItem
                                onClick={() => {
                                  handleCardAction('moveToExile', card.id, zoneToView);
                                  setOpenMenuCardId(null);
                                }}
                              >
                                Move To Exile
                              </MenuItem>
                            )}
                            <MenuItem
                              onClick={() => {
                                handleCardAction('moveToBattlefield', card.id, zoneToView);
                                setOpenMenuCardId(null);
                              }}
                            >
                              Move To Battlefield
                            </MenuItem>
                            <MenuItem onClick={() => handleCardFlip(card.id)}>
                              {zoneOwner.flippedCards?.includes(card.id)
                                ? 'Unflip'
                                : 'Flip'}
                            </MenuItem>
                          </>
                        )}
                      </ControlledMenu>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
        {/* Player Info Modal */}
        {isPlayerInfoModalOpen && (
        <Modal
          isOpen={isPlayerInfoModalOpen}
          onRequestClose={() => setIsPlayerInfoModalOpen(false)}
          contentLabel="Player Info"
          className="player-info-modal text-center"
          overlayClassName="player-info-modal-overlay"
        >
          <h2 className='mb-4 border-bottom pb-2'>Player Info</h2>
          <div className="player-info-modal-content">
            <div className="player-info-modal-item">
              <h4>Username</h4>
              <h5>{otherPlayers.find(player => player.userId === playerInfoId)?.username || currentPlayer.username}</h5>
            </div>
            <div className="player-info-modal-item" style={{ backgroundColor: otherPlayers.find(player => player.userId === playerInfoId)?.color || currentPlayer.color }}></div>
            <div className="player-info-modal-item">
              <h4>Life Total</h4>
              <h5>{otherPlayers.find(player => player.userId === playerInfoId)?.lifeTotal || currentPlayer.lifeTotal}</h5>
            </div>
            <div className="player-info-modal-item">
              <h4>Commander Damage</h4>
                {/* Show commander damage for other players */}
                {currentPlayer.userId !== playerInfoId ? (
                  otherPlayers.find(player => player.userId === playerInfoId)?.commanderDmgs?.filter((damage, index, self) => 
                    // Filter out duplicate entries based on playerId
                    index === self.findIndex(d => d.playerId === damage.playerId)
                  ).map((damage) => {
                    // Find the player who dealt the commander damage
                    const damageDealer = gameState.players.find(p => p.userId === damage.playerId);
                    return damageDealer ? (
                      <h5 key={damage.playerId}>
                        From {damageDealer.username}: {damage.damage}
                      </h5>
                    ) : null;
                  })
                ) : (
                  // Show commander damage for current player
                  currentPlayer.commanderDmgs?.filter((damage, index, self) => 
                    // Filter out duplicate entries based on playerId
                    index === self.findIndex(d => d.playerId === damage.playerId)
                  ).map((damage) => {
                    // Find the player who dealt the commander damage
                    const damageDealer = gameState.players.find(p => p.userId === damage.playerId);
                    return damageDealer ? (
                      <h5 key={damage.playerId}>
                        From {damageDealer.username}: {damage.damage}
                      </h5>
                    ) : null;
                  })
                )}
              </div>
              <div className="player-info-modal-item counters-row">
                <div className="counter-section">
                  <h4>Poison</h4>
                  <div className="counter-controls">
                  {playerInfoId === currentPlayer.userId && (
                    <button 
                      onClick={() => handleCounterAdjust('poison', -1)}
                      className="counter-btn"
                    >-</button>
                  )}
                    <h5>{otherPlayers.find(player => player.userId === playerInfoId)?.poisonCounters || currentPlayer.poisonCounters}</h5>
                    {playerInfoId === currentPlayer.userId && (
                    <button 
                      onClick={() => handleCounterAdjust('poison', 1)}
                      className="counter-btn"
                      >+</button>
                    )}
                  </div>
                </div>

                <div className="counter-section">
                  <h4>Experience</h4>
                  <div className="counter-controls">
                    {playerInfoId === currentPlayer.userId && (
                    <button 
                      onClick={() => handleCounterAdjust('experience', -1)}
                      className="counter-btn"
                    >-</button>
                    )}
                    <h5>{otherPlayers.find(player => player.userId === playerInfoId)?.experienceCounters || currentPlayer.experienceCounters}</h5>
                    {playerInfoId === currentPlayer.userId && (
                    <button 
                      onClick={() => handleCounterAdjust('experience', 1)}
                      className="counter-btn"
                    >+</button>
                    )}
                  </div>
                </div>

                <div className="counter-section">
                  <h4>Energy</h4>
                  <div className="counter-controls">
                    {playerInfoId === currentPlayer.userId && (
                    <button 
                      onClick={() => handleCounterAdjust('energy', -1)}
                      className="counter-btn"
                    >-</button>
                    )}
                    <h5>{otherPlayers.find(player => player.userId === playerInfoId)?.energyCounters || currentPlayer.energyCounters}</h5>
                    {playerInfoId === currentPlayer.userId && (
                    <button 
                      onClick={() => handleCounterAdjust('energy', 1)}
                      className="counter-btn"
                    >+</button>
                    )}
                  </div>
                </div>
              </div>
              <div className="player-info-modal-item">
                <h4>Additional Information</h4>
                <div className="additional-info-list">
                  {otherPlayers.find(player => player.userId === playerInfoId)?.additionalInfo || currentPlayer.additionalInfo?.map((info, index) => (
                    <div key={index} className="additional-info-item">
                      <span>{info}</span>
                      <button 
                        onClick={() => handleRemoveAdditionalInfo(index)}
                        className="remove-info-btn"
                      >
                        ×
                      </button>
                    </div>
                  ))}
                </div>
                {currentPlayer.userId === playerInfoId && (
                  <div className="additional-info-input">
                    <input
                      type="text"
                      value={additionalInfo}
                      onChange={(e) => setAdditionalInfo(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && additionalInfo.trim()) {
                          handleAddAdditionalInfo();
                        }
                      }}
                      className="player-info-modal-input"
                      placeholder="Type and press Enter to add"
                    />
                    <button 
                      onClick={handleAddAdditionalInfo}
                      disabled={!additionalInfo.trim()}
                      className="add-info-btn"
                    >
                      Add
                    </button>
                  </div>
                )}
              </div>
          </div>
          <button onClick={() => setIsPlayerInfoModalOpen(false)} className="player-info-modal-close">
            Close
          </button>
        </Modal>
        )}
        {/* Revealed Hand Modal */}
        {isRevealedHandModal && revealedHandData && (
          <div
            className="zone-menu-overlay"
            onClick={() => {
              setIsRevealedHandModal(false);
              setRevealedHandData(null);
            }}
          >
            <div
              className="zone-menu-content"
              onClick={(e) => e.stopPropagation()}
            >
              <h3 className="mb-3 text-center">
                {revealedHandData.fromPlayerName}'s Hand
              </h3>
              <div className="zone-menu-cards">
                {revealedHandData.hand.map((card) => (
                  <div
                    key={card.id}
                    className="card"
                    style={{ position: 'relative' }}
                  >
                    <Card
                      cardId={card.cardId}
                      uniqueId={card.id}
                      cardImage={getCardImage(card, false)} // Assuming the cards are face-up
                      fromZone="hand"
                      isOwnCard={false}
                      isFlipped={false}
                      onShowPreview={null}
                      onHidePreview={null}
                      className={"zone-card"}
                    />
                  </div>
                ))}
              </div>
              <button
                className="zone-action-btn mt-3"
                onClick={() => {
                  setIsRevealedHandModal(false);
                  setRevealedHandData(null);
                }}
              >
                Close
              </button>
            </div>
          </div>
        )}
        {/* Settings Modal */}
        <Modal
          isOpen={isSettingsModalOpen}
          onRequestClose={() => setIsSettingsModalOpen(false)}
          contentLabel="Settings"
          className="settings-modal text-center"
          overlayClassName="settings-modal-overlay"
        >
          <h2 className='mb-4'>Settings</h2>
          <div className="settings-modal-content">
            <div className="settings-modal-item">
              <p>Ctrl + Click library to draw</p>
              <p>Left/Right Click Counter Numbers to increment/decrement them</p>
            </div>
          </div>
        </Modal>
        {/* Counter Modal */}
        <Modal
          isOpen={isCounterModalOpen}
          onRequestClose={() => setIsCounterModalOpen(false)}
          contentLabel="Add Counter"
          className="counter-modal text-center"
          overlayClassName="counter-modal-overlay"
        >
          <h2 className='mb-4'>Add Counter</h2>
          <form onSubmit={handleAddCounter}>
            <div>
              <label><h4>Text</h4></label>
              <input
                type="text"
                value={counterText}
                onChange={(e) => setCounterText(e.target.value)}
                className="counter-input"
              />
            </div>
            <div>
              <label><h4>Number 1</h4></label>
              <input
                type="number"
                value={counterNumber1}
                onChange={(e) => setCounterNumber1(e.target.value)}
                className="counter-input"
              />
            </div>
            <div>
              <label><h4>Number 2</h4></label>
              <input
                type="number"
                value={counterNumber2}
                onChange={(e) => setCounterNumber2(e.target.value)}
                className="counter-input"
              />
            </div>
            <div>
              <label><h4>Fusable?</h4></label>
              <input
                type="checkbox"
                checked={isFusable}
                onChange={(e) => setIsFusable(e.target.checked)}
                className="counter-input"
              />
            </div>
            <button type="submit" className="counter-modal-add w-50 mt-3">Add Counter</button>
          </form>
          <button onClick={() => setIsCounterModalOpen(false)} className="counter-modal-close w-50">
            Close
          </button>
        </Modal>
      </div>
    </DndProvider>
  );
}

export default Room;